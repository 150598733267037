import React, { useState } from "react";
import styled from "styled-components";

import { Search } from "react-feather";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  FormControl,
  InputAdornment,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Typography as MuiTypography,
  OutlinedInput,
} from "@mui/material";
import { spacing } from "@mui/system";

import OrderTable from "../../../components/OrderTable.js";

const axios = require("axios").default;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Typography = styled(MuiTypography)(spacing);

const Centered = styled.div`
  text-align: center;
`;

function OrderLookup() {
  const [isLoading, setLoading] = React.useState(false);
  const [input, setInput] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(
    "Scan the barcode or enter the serial number of the device."
  );
  const [orders, setOrders] = useState();

  function handleChange(e) {
    e.preventDefault();
    setInput(e.target.value);
  }

  const navigate = useNavigate();

  function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();

    axios.post("/lookup/", { search_text: input }).then((response) => {
      setLoading(false);
      setError(false);
      let result = response.data;
      if (result.length) {
        // If 1 record returned
        if (result.length === 1) {
          let order = result[0];
          setSuccess(true);
          setInput("");

          let timeout = new Promise(function (resolve, reject) {
            resolve(1);

            setTimeout(() => resolve(), 2000);
          });

          timeout.then(navigate("/order/" + order.id));
        } else if (result.length > 1) {
          // If multiple orders returned
          setOrders(result);
          setSuccess(true);
          setMessage("Multiple orders found for " + input);
        } else {
          setError(true);
          setMessage("Something went wrong. Try again.");
        }
      } else {
        // If no orders found
        setError(true);
        setMessage("No open orders found for " + input);
      }
    });
  }

  return (
    <React.Fragment>
      <div
        style={{
          width: "100%",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <Centered>
          <Typography variant="h1" gutterBottom>
            Order Lookup
          </Typography>
          <Typography variant="h4" gutterBottom>
            Enter PO or serial #.
          </Typography>
          <form onSubmit={handleSubmit}>
            <FormControl sx={{ m: 2 }} variant="outlined">
              <OutlinedInput
                id="serial-number"
                type="text"
                value={input}
                autoFocus
                placeholder="xxxx-xxxxxxxx"
                onChange={handleChange}
                style={{ fontSize: 20 }}
                startAdornment={
                  <InputAdornment position="start">
                    <Search size={50} />
                  </InputAdornment>
                }
              />
            </FormControl>
          </form>
          {!success && !error && (
            <Typography>Scan a barcode or enter with keyboard.</Typography>
          )}
        </Centered>
        {error && (
          <Alert mb={4} variant="outlined" severity="error">
            {message}
          </Alert>
        )}
        {success && (
          <Alert mb={4} variant="outlined" severity="success">
            {message}
          </Alert>
        )}
        {orders && <OrderTable orders={orders} />}
      </div>
    </React.Fragment>
  );
}

export default OrderLookup;
