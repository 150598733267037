import React from "react";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import Page404 from "./pages/auth/Page404";

// Components

// DMG Pages
import OrderLookup from "./pages/RMA/orders/OrderLookup";
import ProductAdd from "./pages/RMA/products/add";
import ProductEdit from "./pages/RMA/products/edit";
import InventoryList from "./pages/RMA/products/inventory";
import ProductList from "./pages/RMA/products/list";

// Form components

// Icon components

// Page components

// Table components

// Documentation

// Landing

// Protected routes
import CreateCustomer from "./pages/RMA/customers/CreateCustomer";
import CustomerList from "./pages/RMA/customers/CustomerList";
import CustomerEdit from "./pages/RMA/customers/edit";
import OrderCreator from "./pages/RMA/orders/OrderCreator";
import OrderDetail from "./pages/RMA/orders/OrderDetails";
import OrderList from "./pages/RMA/orders/OrderList";
import Quote from "./pages/RMA/quotes/Quote";
import QuoteCreator from "./pages/RMA/quotes/QuoteCreator";
import QuoteList from "./pages/RMA/quotes/QuoteList";

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <OrderList />,
      },
      {
        path: "active",
        element: <OrderList status="active" />,
      },
      {
        path: "Completed",
        element: <OrderList status="complete" />,
      },
    ],
  },
  {
    path: "orders",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <OrderList />,
      },
      {
        path: "ordered",
        element: <OrderList status="ordered" />,
      },
      {
        path: "accepted",
        element: <OrderList status="accepted" />,
      },
      {
        path: "held",
        element: <OrderList status="held" />,
      },
      {
        path: "picked",
        element: <OrderList status="picked" />,
      },
      {
        path: "shipped",
        element: <OrderList status="shipped" />,
      },
    ],
  },
  {
    path: "order",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <OrderList />,
      },
      {
        path: "lookup",
        element: <OrderLookup />,
      },
      {
        path: ":id",
        element: <OrderDetail />,
      },
      {
        path: "create",
        element: <OrderCreator />,
      },
      {
        path: "new",
        element: <OrderCreator />,
      },
      {
        path: "add",
        element: <OrderCreator />,
      },
    ],
  },
  {
    path: "products",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProductList />,
      },
    ],
  },
  {
    path: "inventory",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <InventoryList />,
      },
    ],
  },
  {
    path: "quotes",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <QuoteList />,
      },
      {
        path: ":id",
        element: <Quote />,
      },
      {
        path: "add",
        element: <QuoteCreator />,
      },
      {
        path: "quoted",
        element: <QuoteList status="quoted" />,
      },
      {
        path: "converted",
        element: <QuoteList status="converted" />,
      },
    ],
  },
  {
    path: "quote",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <QuoteCreator />,
      },
      {
        path: ":id",
        element: <Quote />,
      },
      {
        path: "add",
        element: <QuoteCreator />,
      },
      {
        path: "create",
        element: <QuoteCreator />,
      },
    ],
  },
  {
    path: "product",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProductList />,
      },
      {
        path: ":id",
        element: <ProductEdit />,
      },
      {
        path: "add",
        element: <ProductAdd />,
      },
    ],
  },
  {
    path: "customers",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <CustomerList />,
      },
      {
        path: ":id",
        element: <CustomerEdit />,
      },
      {
        path: "add",
        element: <CreateCustomer />,
      },
      {
        path: "create",
        element: <CreateCustomer />,
      },
    ],
  },
  {
    path: "customer",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <CustomerList />,
      },
      {
        path: ":id",
        element: <CustomerEdit />,
      },
      {
        path: "add",
        element: <CreateCustomer />,
      },
      {
        path: "create",
        element: <CreateCustomer />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
