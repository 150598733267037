import WidgetsIcon from "@mui/icons-material/Widgets";
import { FileText, Package, Plus, ShoppingCart, Users } from "react-feather";

import { Add, ShowChart } from "@mui/icons-material";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import FlightTakeoffOutlinedIcon from "@mui/icons-material/FlightTakeoffOutlined";
import InventoryIcon from "@mui/icons-material/Inventory";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";

import useAuth from "../../hooks/useAuth";

function quoteSection(summary) {
  return [
    {
      href: "/quote/create",
      icon: Add,
      title: "Create Quote",
      badge: null,
    },
    {
      href: "/quotes/quoted",
      icon: FileText,
      title: "Quoted",
      badge:
        typeof summary?.quotes_quoted === "number"
          ? summary.quotes_quoted.toString()
          : "-",
    },
    {
      href: "/quotes/converted",
      icon: DoneOutlineOutlinedIcon,
      title: "Converted",
      badge:
        typeof summary?.quotes_converted === "number"
          ? summary.quotes_converted.toString()
          : "-",
    },
    {
      href: "/quotes",
      icon: InventoryIcon,
      title: "All Quotes",
      badge:
        typeof summary?.quotes_converted === "number"
          ? summary.quotes.toString()
          : "-",
    },
  ];
}

function orderSection(summary) {
  return [
    {
      href: "/order/create",
      icon: Add,
      title: "Create Order",
      badge: null,
    },
    {
      href: "/orders/ordered",
      icon: ShoppingCart,
      title: "Ordered",
      badge:
        typeof summary?.ordered === "number" ? summary.ordered.toString() : "-",
    },
    {
      href: "/orders/accepted",
      icon: ThumbUpOffAltIcon,
      title: "Accepted",
      badge:
        typeof summary?.accepted === "number"
          ? summary.accepted.toString()
          : "-",
    },
    {
      href: "/orders/held",
      icon: ReportGmailerrorredIcon,
      title: "Held",
      badge: typeof summary?.held === "number" ? summary.held.toString() : "-",
    },
    {
      href: "/orders/picked",
      icon: PrecisionManufacturingOutlinedIcon,
      title: "Picked",
      badge:
        typeof summary?.picked === "number" ? summary.picked.toString() : "-",
    },
    {
      href: "/orders/shipped",
      icon: FlightTakeoffOutlinedIcon,
      title: "Shipped",
      badge:
        typeof summary?.shipped === "number" ? summary.shipped.toString() : "-",
    },
    {
      href: "/orders",
      icon: InventoryIcon,
      title: "All Orders",
      badge:
        typeof summary?.orders === "number" ? summary.orders.toString() : "-",
    },
  ];
}
function customerSection(summary) {
  return [
    {
      href: "/customers",
      icon: Users,
      title:
        "Customers (" +
        (typeof summary?.customers === "number"
          ? summary.customers.toString()
          : "-") +
        ")",
    },
  ];
}

function productSection(summary, user) {
  let menuitems = [
    {
      href: "/products",
      icon: WidgetsIcon,
      title:
        "Products (" +
        (typeof summary?.products === "number"
          ? summary.products.toString()
          : "-") +
        ")",
    },
  ];

  if (user?.is_staff) {
    menuitems.push({
      href: "/inventory",
      icon: ShowChart,
      title: "Inventory",
    });
  }

  return menuitems;
}

const shipmentSection = [
  {
    href: "/shipments",
    icon: Package,
    title: "Shipments",
  },
  {
    href: "/shipments/new",
    icon: Plus,
    title: "Add",
  },
];

function NavItems(summary) {
  const { user } = useAuth();

  if (user?.quoting_enabled) {
    return [
      {
        title: "Quotes",
        pages: quoteSection(summary),
      },
      {
        title: "Orders",
        pages: orderSection(summary),
      },
      {
        title: "Customers",
        pages: customerSection(summary),
      },
      {
        title: "Products",
        pages: productSection(summary, user),
      },
    ];
  } else {
    return [
      {
        title: "Orders",
        pages: orderSection(summary),
      },
      {
        title: "Customers",
        pages: customerSection(summary),
      },
      {
        title: "Products",
        pages: productSection(summary, user),
      },
    ];
  }
}

export default NavItems;
