import SearchIcon from "@mui/icons-material/Search";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";

import {
  Alert,
  Autocomplete,
  Button,
  CardContent,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Typography as MuiTypography,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

import { spacing } from "@mui/system";

import { Save } from "@mui/icons-material";
import { ErrorMessage, Form, Formik } from "formik";
import MuiPhoneNumber from "material-ui-phone-number";
import { ContactSelector } from "../../../components/ContactSelector.js";
import Cart from "../../../components/cart/Cart.js";
import CartSummary from "../../../components/cart/CartSummary.js";
import { CartContext } from "../../../contexts/CartProvider.js";
import useAuth from "../../../hooks/useAuth.js";

const axios = require("axios").default;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Typography = styled(MuiTypography)(spacing);

const Centered = styled.div`
  text-align: center;
`;

const ProductImg = styled.img`
  display: inline-block;
  height: auto;
  width: 128px;
`;

const StatsIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;

  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const ProductsChip = styled(Chip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const processProgress = (order) => {
  if (order.status === "Cancel") {
    return -1;
  }
  if (order.status === "Hold") {
    return 0;
  }
  if (order.status === "Modified") {
    return 0;
  }
  if (order.status === "Shipped") {
    return 3;
  }
  if (order.status === "Picked") {
    return 2;
  }
  if (order.status === "Accepted") {
    return 1;
  }
  if (order.status === "Ordered") {
    return 0;
  }
  return 0;
};

function OrderCreator(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user } = useAuth();
  const [isLoading, setLoading] = React.useState(true);
  const [showProductSelector, setShowProductSelector] = React.useState(false);
  const { id } = useParams();

  // Customer Address book variables
  const [selectedContact, setSelectedContact] = React.useState(
    state?.customer ? state.customer : null
  );
  const [contactAction, setContactAction] = React.useState(0);
  // 0 = Save contact information for this quote only
  // 1 = Update existing contact information
  // 2 = Save contact information as a new contact.

  const handleContactAction = (event, newSelection) => {
    setContactAction(newSelection);
  };

  const [order, setOrder] = useState({});

  const [edit, setEdit] = React.useState(true);
  const [quotes, setQuotes] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setShowProductSelector(false);
    setOpen(false);
  };
  const handleOpen = () => {
    setShowProductSelector(true);
    setOpen(true);
  };

  const [initialValues, setInitialValues] = React.useState({
    po_number: "",
    contact_name: state?.customer ? state.customer.name : "",
    contact_phone: state?.customer ? state.customer.phone : "",
    contact_email: state?.customer ? state.customer.email : "",
    company_name: state?.customer ? state.customer.company_name : "",
    order_confirmation: null,
    billing_contact_name: state?.customer
      ? state.customer.billing_contact_name
      : "",
    billing_street_address: state?.customer
      ? state.customer.billing_street_address
      : "",
    billing_street_address2: state?.customer
      ? state.customer.billing_street_address2
      : "",
    billing_city: state?.customer ? state.customer.billing_city : "",
    billing_state: state?.customer ? state.customer.billing_state : "",
    billing_zip: state?.customer ? state.customer.billing_zip : "",
    billing_phone: state?.customer ? state.customer.billing_phone : "",
    shipping_contact_name: state?.customer
      ? state.customer.shipping_contact_name
      : "",
    shipping_street_address: state?.customer
      ? state.customer.shipping_street_address
      : "",
    shipping_street_address2: state?.customer
      ? state.customer.shipping_street_address2
      : "",
    shipping_city: state?.customer ? state.customer.shipping_city : "",
    shipping_state: state?.customer ? state.customer.shipping_state : "",
    shipping_zip: state?.customer ? state.customer.shipping_zip : "",
    shipping_phone: state?.customer ? state.customer.shipping_phone : "",
    comments: "",
    stakeholders: [],
    discount: "0.00",
    shipping_cost: "",
    cart_items: state?.cart_items || [],
  });
  const [cartItems, setCartItems] = React.useState(
    props.items ? props.items : []
  );

  function handleContactSelection(contact) {
    if (contact) {
      setSelectedContact(contact);
      setContactAction(0);
      let newContact = {
        contact_name: contact.name,
        contact_email: contact.email,
        contact_phone: contact.phone,
        company_name: contact.company_name,

        billing_contact_name: contact.billing_contact_name,
        billing_street_address: contact.billing_street_address,
        billing_street_address2: contact.billing_street_address2,
        billing_city: contact.billing_city,
        billing_state: contact.billing_state,
        billing_zip: contact.billing_zip,
        billing_phone: contact.billing_phone,
        shipping_contact_name: contact.shipping_contact_name,
        shipping_street_address: contact.shipping_street_address,
        shipping_street_address2: contact.shipping_street_address2,
        shipping_city: contact.shipping_city,
        shipping_state: contact.shipping_state,
        shipping_zip: contact.shipping_zip,
        shipping_phone: contact.shipping_phone,
      };

      setInitialValues((prev) => {
        return { ...prev, ...newContact };
      });
    }
  }

  const ContactActionText = () => {
    switch (contactAction) {
      case 0:
        // code block
        return (
          <Typography variant="body1">
            Save contact information for this quote only.
          </Typography>
        );
      case 1:
        // code block
        return (
          <Typography variant="body1">
            Update existing contact information.
          </Typography>
        );
      case 2:
        // code block
        return (
          <Typography variant="body1">
            Save contact information as a new contact.
          </Typography>
        );
        break;
      default:
        return (
          <Typography variant="body1">
            Save contact information for this quote only.
          </Typography>
        );
    }
  };

  const useStyles = makeStyles(() => ({
    root: {
      "& .Mui-active": {},
      "& .Mui-completed": { color: "green" },
      "& .Mui-disabled .MuiStepIcon-root": { color: "gray" },
      "& .MuiStepIcon-text": { color: "black" },
    },
  }));

  const c = useStyles();

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const timerRef = useRef();
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const { cart } = useContext(CartContext);
  const items = cart.map((item) => {
    return {
      product: { ...item },
      quantity: item.quantity,
      discount: item.discount || 0,
    };
  });

  useEffect(() => {
    if (user?.email !== undefined) {
      setInitialValues((prev) => {
        return { ...prev, stakeholders: [user.email] };
      });
    }
  }, [user]);

  useEffect(() => {
    setLoading(true);
    // setOrders([]);
    var url = "/customers/" + "?search=" + searchQuery;
    // if (props.status) {
    //   url = "/orders/?status=" + props.status;
    // }
    axios
      .get(url)
      .then((response) => {
        setSearchResults(response.data.results);
      })
      .then(() => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [searchQuery]);

  function handleFileUpload(e) {
    let file = e.target.files[0];
    // const newName = uuidv4();
    // const name = newName + file.name.substring(file.name.lastIndexOf("."));
    file = new File([file], file.name, { type: file.type });
    setQuotes((quotes) => [...quotes, file]);
  }
  const [value, setValue] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");

  return (
    <React.Fragment>
      <Helmet title="Order Status" />
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={Yup.object().shape({
          po_number: Yup.string().required("PO number is required"),
          contact_name: Yup.string()
            .max(250)
            .required("Contact name is required"),
          company_name: Yup.string().required("Email is required"),
          contact_phone: Yup.string()
            .max(25)
            .required("Phone number is required"),
          contact_email: Yup.string()
            .email("Must be a valid email")
            .required("Email is Required"),
          billing_contact_name: Yup.string().required(
            "Billing contact name is required"
          ),
          billing_phone: Yup.string()
            .max(25)
            .required("Billing phone is required"),
          billing_street_address: Yup.string().required(
            "Billing street adress is required"
          ),
          billing_city: Yup.string().required("Billing city is required"),
          billing_state: Yup.string()
            .max(2)
            .required("Billing state is required"),
          billing_zip: Yup.string().max(7).required("Billing zip is required"),
          shipping_contact_name: Yup.string().required(
            "Shipping contact name is required"
          ),
          shipping_phone: Yup.string()
            .max(25)
            .required("Shipping phone is required"),
          shipping_street_address: Yup.string().required(
            "Shipping street adress is required"
          ),
          shipping_city: Yup.string().required("Shipping city is required"),
          shipping_state: Yup.string()
            .max(2)
            .required("Shipping state is required"),
          shipping_zip: Yup.string()
            .max(7)
            .required("Shipping zip is required"),
          cart_items: Yup.array()
            .min(1, "At least one item is required")
            .required("At least one item is required"),
          discount: Yup.number()
            .min(0.0, "Discount must be 0-100")
            .max(100.0, "Discount must be less than 100"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          // Save contact info
          if (contactAction === 0) {
            // Do nothing
          } else if (contactAction === 1) {
            // Update existing
            let updatedContact = {
              name: values.contact_name,
              email: values.contact_email,
              phone: values.contact_phone,
              company_name: values.company_name,
              billing_contact_name: values.billing_contact_name,
              billing_street_address: values.billing_street_address,
              billing_street_address2: values.billing_street_address2,
              billing_city: values.billing_city,
              billing_state: values.billing_state,
              billing_zip: values.billing_zip,
              billing_phone: values.billing_phone,
              shipping_contact_name: values.shipping_contact_name,
              shipping_street_address: values.shipping_street_address,
              shipping_street_address2: values.shipping_street_address2,
              shipping_city: values.shipping_city,
              shipping_state: values.shipping_state,
              shipping_zip: values.shipping_zip,
              shipping_phone: values.shipping_phone,
            };

            axios
              .put("/customers/" + selectedContact.id + "/", updatedContact)
              .then((response) => {})
              .catch((error) => {
                console.log("Error updating contact: ", error);
              });
          } else if (contactAction === 2) {
            // Save as new
            let newContact = {
              name: values.contact_name,
              email: values.contact_email,
              phone: values.contact_phone,
              company_name: values.company_name,
              billing_contact_name: values.billing_contact_name,
              billing_street_address: values.billing_street_address,
              billing_street_address2: values.billing_street_address2,
              billing_city: values.billing_city,
              billing_state: values.billing_state,
              billing_zip: values.billing_zip,
              billing_phone: values.billing_phone,
              shipping_contact_name: values.shipping_contact_name,
              shipping_street_address: values.shipping_street_address,
              shipping_street_address2: values.shipping_street_address2,
              shipping_city: values.shipping_city,
              shipping_state: values.shipping_state,
              shipping_zip: values.shipping_zip,
              shipping_phone: values.shipping_phone,
            };

            axios
              .post("/customers/", newContact)
              .then((response) => {})
              .catch((error) => {
                console.log("Error updating contact: ", error);
              });
          }

          values.new_cart_items = values.cart_items.map((item) => {
            return {
              product: item.product.id,
              quantity: item.quantity,
              discount: item.discount || 0,
            };
          });

          let stakeholdersJson = {
            emails: values.stakeholders,
          };

          values.stakeholders = stakeholdersJson;

          try {
            axios
              .post("/orders/", values)
              .then((response) => {
                if (response.data) {
                  if (values.order_confirmation?.name) {
                    const formData = new FormData();
                    formData.append(
                      "order_confirmation",
                      values.order_confirmation
                    );
                    axios
                      .post(
                        "/orders/" + response.data.id + "/upload_confirmation/",
                        formData
                      )
                      .then((response) => {
                        console.log(
                          "|| Confirmation file uploaded successfully"
                        );
                        setOrder(response.data);
                      });
                  }
                  setOrder(response.data);
                }
                navigate("/order/" + response.data.id);
              })
              .catch((error) => {
                console.log("Error! ", error);
              });
          } catch (error) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {(form) => (
          <Form>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Typography variant="h1" gutterBottom display="inline">
                  Order
                </Typography>

                <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                  <Link component={NavLink} to="/">
                    Dashboard
                  </Link>
                  <Link component={NavLink} to="/orders">
                    Orders
                  </Link>
                  <Typography>Order Create</Typography>
                </Breadcrumbs>
              </div>
              <div style={{ display: "flex", flexDirection: "column-reverse" }}>
                <IconButton aria-label="details" size="large" type="submit">
                  <Save />
                </IconButton>
              </div>
            </div>
            <Divider my={6} />
            {
              <div>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Card mb={6}>
                      <CardContent>
                        <Typography variant="h6">Contact</Typography>

                        <Grid
                          item
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: "white",
                            padding: 0,
                            marginTop: 10,
                            // marginLeft: 40,
                            alignItems: "end",
                            // borderRadius: 4,
                            paddingBottom: 5,
                          }}
                          justifyContent="space-between"
                          container
                          // spacing={10}
                        >
                          <SearchIcon
                            style={{ marginBottom: 10, marginLeft: 20 }}
                          />
                          <div
                            style={{
                              flex: 1,
                              paddingRight: 20,
                              paddingLeft: 10,
                            }}
                          >
                            <ContactSelector
                              selectedContact={selectedContact}
                              handleContactSelection={handleContactSelection}
                            />
                          </div>
                        </Grid>
                        <Grid container spacing={6}>
                          <Grid item xs={12} sm={12}>
                            <TextField
                              // id="company_name"
                              name="company_name"
                              label="Company Name"
                              fullWidth
                              variant="outlined"
                              my={2}
                              value={form.values.company_name}
                              // error={Boolean(form.errors.firstName && form.touched.firstName)}
                              helperText={
                                form.touched.company_name &&
                                form.errors.company_name
                              }
                              error={Boolean(
                                form.touched.company_name &&
                                  form.errors.company_name
                              )}
                              onChange={form.handleChange}
                              onBlur={form.handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              name="contact_name"
                              label="Contact Name"
                              fullWidth
                              variant="outlined"
                              my={2}
                              value={form.values.contact_name}
                              helperText={
                                form.touched.contact_name &&
                                form.errors.contact_name
                              }
                              error={Boolean(
                                form.touched.contact_name &&
                                  form.errors.contact_name
                              )}
                              onChange={form.handleChange}
                              onBlur={form.handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MuiPhoneNumber
                              name="contact_phone"
                              label="Phone Number"
                              value={form.values.contact_phone}
                              data-cy="number"
                              defaultCountry={"us"}
                              onChange={form.handleChange("contact_phone")}
                              onBlur={form.handleBlur}
                              helperText={
                                form.touched.contact_phone &&
                                form.errors.contact_phone
                              }
                              error={Boolean(
                                form.touched.contact_phone &&
                                  form.errors.contact_phone
                              )}
                              fullWidth
                              type="tel"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <TextField
                              // id="contact_name"
                              name="contact_email"
                              label="Email"
                              fullWidth
                              variant="outlined"
                              my={2}
                              value={form.values.contact_email}
                              helperText={
                                form.touched.contact_email
                                  ? form.errors.contact_email
                                  : ""
                              }
                              error={
                                form.touched.contact_email &&
                                Boolean(form.errors.contact_email)
                              }
                              onChange={form.handleChange}
                              onBlur={form.handleBlur}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                    <Card mb={6}>
                      <CardContent>
                        <Typography variant="h6">Purchase Order</Typography>
                        <div
                          style={{
                            margin: 20,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <TextField
                            id="po_number"
                            name="po_number"
                            label="Enter the PO number"
                            style={{ width: "500px", marginBottom: 30 }}
                            value={form.values.po_number}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            helperText={
                              form.touched.po_number && form.errors.po_number
                            }
                            error={Boolean(
                              form.touched.po_number && form.errors.po_number
                            )}
                          />
                          <Typography style={{ marginBottom: 20 }}>
                            (Optional) Upload a purchase order pdf
                          </Typography>
                          <input
                            type="file"
                            name="order_confirmation"
                            onChange={(e) =>
                              form.setFieldValue(
                                "order_confirmation",
                                e.currentTarget.files[0]
                              )
                            }
                          />
                        </div>
                      </CardContent>
                    </Card>
                    <Grid container spacing={6} marginBottom={6}>
                      <Grid item xs={12} sm={6}>
                        <Card>
                          <CardContent>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                              }}
                            >
                              <Typography variant="h6">Bill To</Typography>
                              <Button
                                onClick={() => {
                                  const newFormValues = form.values;
                                  newFormValues.shipping_contact_name =
                                    newFormValues.billing_contact_name;
                                  newFormValues.shipping_street_address =
                                    newFormValues.billing_street_address;
                                  newFormValues.shipping_street_address2 =
                                    newFormValues.billing_street_address2;
                                  newFormValues.shipping_city =
                                    newFormValues.billing_city;
                                  newFormValues.shipping_state =
                                    newFormValues.billing_state;
                                  newFormValues.shipping_zip =
                                    newFormValues.billing_zip;
                                  newFormValues.shipping_phone =
                                    newFormValues.billing_phone;
                                  form.setValues({ ...newFormValues });
                                }}
                              >
                                Copy to Shipping
                              </Button>
                            </div>
                            <Grid
                              container
                              spacing={6}
                              style={{ marginTop: 5 }}
                            >
                              <Grid item xs={12} sm={12}>
                                <TextField
                                  name="billing_contact_name"
                                  label="Billing Contact Name"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.billing_contact_name}
                                  helperText={
                                    form.touched.billing_contact_name &&
                                    form.errors.billing_contact_name
                                  }
                                  error={Boolean(
                                    form.touched.billing_contact_name &&
                                      form.errors.billing_contact_name
                                  )}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <MuiPhoneNumber
                                  name="billing_phone"
                                  label="Billing Phone"
                                  value={form.values.billing_phone}
                                  data-cy="number"
                                  defaultCountry={"us"}
                                  onChange={form.handleChange("billing_phone")}
                                  onBlur={form.handleBlur}
                                  helperText={
                                    form.touched.billing_phone &&
                                    form.errors.billing_phone
                                  }
                                  error={Boolean(
                                    form.touched.billing_phone &&
                                      form.errors.billing_phone
                                  )}
                                  fullWidth
                                  type="tel"
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField
                                  name="billing_street_address"
                                  label="Billing Street Address"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.billing_street_address}
                                  helperText={
                                    form.touched.billing_street_address &&
                                    form.errors.billing_street_address
                                  }
                                  error={Boolean(
                                    form.touched.billing_street_address &&
                                      form.errors.billing_street_address
                                  )}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField
                                  name="billing_street_address2"
                                  label="Billing Street Address2"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.billing_street_address2}
                                  // error={Boolean(form.errors.firstName && form.touched.firstName)}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <TextField
                                  name="billing_city"
                                  label="City"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.billing_city}
                                  helperText={
                                    form.touched.billing_city &&
                                    form.errors.billing_city
                                  }
                                  error={Boolean(
                                    form.touched.billing_city &&
                                      form.errors.billing_city
                                  )}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                                <ErrorMessage
                                  name="billing_city"
                                  component="div"
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  name="billing_state"
                                  label="State"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.billing_state}
                                  inputProps={{ maxLength: 2 }}
                                  helperText={
                                    form.touched.billing_state &&
                                    form.errors.billing_state
                                  }
                                  error={Boolean(
                                    form.touched.billing_state &&
                                      form.errors.billing_state
                                  )}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField
                                  name="billing_zip"
                                  label="Zip"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.billing_zip}
                                  helperText={
                                    form.touched.billing_zip &&
                                    form.errors.billing_zip
                                  }
                                  error={Boolean(
                                    form.touched.billing_zip &&
                                      form.errors.billing_zip
                                  )}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Card>
                          <CardContent>
                            <Typography variant="h6">Ship To</Typography>
                            <Grid
                              container
                              spacing={6}
                              style={{ marginTop: 5 }}
                            >
                              <Grid item xs={12} sm={12}>
                                <TextField
                                  name="shipping_contact_name"
                                  label="Shipping Contact Name"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.shipping_contact_name}
                                  helperText={
                                    form.touched.shipping_contact_name &&
                                    form.errors.shipping_contact_name
                                  }
                                  error={Boolean(
                                    form.touched.shipping_contact_name &&
                                      form.errors.shipping_contact_name
                                  )}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <MuiPhoneNumber
                                  name="shipping_phone"
                                  label="Shipping Phone"
                                  value={form.values.shipping_phone}
                                  data-cy="number"
                                  defaultCountry={"us"}
                                  onChange={form.handleChange("shipping_phone")}
                                  onBlur={form.handleBlur}
                                  helperText={
                                    form.touched.shipping_phone &&
                                    form.errors.shipping_phone
                                  }
                                  error={Boolean(
                                    form.touched.shipping_phone &&
                                      form.errors.shipping_phone
                                  )}
                                  fullWidth
                                  type="tel"
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField
                                  name="shipping_street_address"
                                  label="Shipping Street Address"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.shipping_street_address}
                                  helperText={
                                    form.touched.shipping_street_address &&
                                    form.errors.shipping_street_address
                                  }
                                  error={Boolean(
                                    form.errors.shipping_street_address &&
                                      form.touched.shipping_street_address
                                  )}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField
                                  name="shipping_street_address2"
                                  label="Shipping Street Address2"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.shipping_street_address2}
                                  // error={Boolean(form.errors.firstName && form.touched.firstName)}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <TextField
                                  name="shipping_city"
                                  label="City"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.shipping_city}
                                  helperText={
                                    form.touched.shipping_city &&
                                    form.errors.shipping_city
                                  }
                                  error={Boolean(
                                    form.errors.shipping_city &&
                                      form.touched.shipping_city
                                  )}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  name="shipping_state"
                                  label="State"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.shipping_state}
                                  inputProps={{ maxLength: 2 }}
                                  helperText={
                                    form.touched.shipping_state &&
                                    form.errors.shipping_state
                                  }
                                  error={Boolean(
                                    form.errors.shipping_state &&
                                      form.touched.shipping_state
                                  )}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField
                                  name="shipping_zip"
                                  label="Zip"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.shipping_zip}
                                  helperText={
                                    form.touched.shipping_zip &&
                                    form.errors.shipping_zip
                                  }
                                  error={Boolean(
                                    form.errors.shipping_zip &&
                                      form.touched.shipping_zip
                                  )}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Card>
                        <CardContent>
                          <Typography variant="h6">Contact Actions</Typography>
                          <br />
                          <ToggleButtonGroup
                            color="primary"
                            value={contactAction}
                            exclusive
                            onChange={handleContactAction}
                            aria-label="Platform"
                          >
                            <ToggleButton value={0}>Save</ToggleButton>
                            {selectedContact && (
                              <ToggleButton value={1}>
                                Update Existing
                              </ToggleButton>
                            )}
                            <ToggleButton value={2}>Save as New</ToggleButton>
                          </ToggleButtonGroup>
                          <br />
                          <br />
                          <ContactActionText />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Card my={6}>
                      <CardContent>
                        <Typography variant="h6">Comments</Typography>
                        <div style={{ margin: 20 }}>
                          <TextField
                            // id="contact_name"
                            multiline
                            rows={4}
                            name="comments"
                            label="comments"
                            fullWidth
                            variant="outlined"
                            my={2}
                            value={form.values.comments}
                            // error={Boolean(form.errors.firstName && form.touched.firstName)}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            inputProps={{ maxLength: 250 }}
                          />
                        </div>
                      </CardContent>
                    </Card>
                    <Card my={6}>
                      <CardContent>
                        <Typography variant="h6">Stakeholders</Typography>
                        <div style={{ margin: 20 }}>
                          <Autocomplete
                            multiple
                            freeSolo
                            id="tags-standard"
                            options={[""]} // todo grab customer field options
                            value={form.values.stakeholders}
                            inputValue={inputValue}
                            onChange={(event, newValue) => {
                              setValue(newValue);
                              form.setFieldValue("stakeholders", newValue);
                            }}
                            onInputChange={(event, newInputValue) => {
                              const options = newInputValue.split(",");

                              if (options.length > 1) {
                                setValue(
                                  value
                                    .concat(options)
                                    .map((x) => x.trim())
                                    .filter((x) => x)
                                );
                              } else {
                                setInputValue(newInputValue);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Stakeholders"
                                placeholder="Add stakeholer email"
                              />
                            )}
                          />
                        </div>
                      </CardContent>
                    </Card>
                    <Card>
                      <CardContent>
                        <div>
                          {Boolean(
                            form.errors.cart_items && form.touched.cart_items
                          ) ? (
                            <Alert severity="error">
                              {form.touched.cart_items &&
                                form.errors.cart_items}
                            </Alert>
                          ) : (
                            <></>
                          )}
                          <Cart
                            cartItems={form.values.cart_items}
                            setCartItems={(update) => {
                              const prev = form.values;
                              if (typeof update === "function") {
                                prev.cart_items = update(prev.cart_items);
                                console.log(
                                  "|| setting initial values: ",
                                  prev
                                );
                                form.setFieldValue(
                                  "cart_items",
                                  prev.cart_items
                                );
                              } else {
                                form.setFieldValue("cart_items", update);
                              }
                            }}
                            discount={form.values.discount}
                            edit={edit}
                          />
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Card my={1}>
                  <CardContent>
                    <Grid container rowSpacing={1}>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          justifyContent: "right",
                          alignItems: "center",
                          textAlign: "right",
                        }}
                        xs={12}
                      >
                        <h4 style={{ margin: 0, marginRight: 5 }}>
                          Additional Discount:{" "}
                        </h4>
                        <TextField
                          id="discount"
                          name="discount"
                          label="% Discount"
                          fullWidth
                          variant="outlined"
                          my={2}
                          type="number"
                          value={form.values.discount}
                          error={Boolean(
                            form.errors.discount && form.touched.discount
                          )}
                          helperText={
                            form.touched.discount && form.errors.discount
                          }
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          style={{
                            maxWidth: 200,
                            alignSelf: "flex-end",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card my={1}>
                  <CardContent>
                    <CartSummary
                      cartItems={form.values.cart_items}
                      discount={form.values.discount}
                      shipping_cost={form.values.shipping_cost}
                    />
                  </CardContent>
                </Card>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: 10 }}
                  // disabled={isSubmitting}
                >
                  Submit Order
                </Button>
              </div>
            }
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default OrderCreator;
