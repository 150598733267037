import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { currencyFormatter } from "../../../utils/currencyFormatter";

const Item = ({ item, handleAddToCart }) => {
  const StockStatus = ({ qty_value }) => {
    if (qty_value > 0) {
      return <Typography>Qty: {qty_value}</Typography>;
    } else if (qty_value === 0) {
      return <Typography color="error">Out of Stock</Typography>;
    } else {
      return null;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        width: "100%",
        border: "1px solid lightblue",
        borderRadius: 1,
        height: "100%",
        backgroundColor: "white",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        cursor: "pointer",
      }}
      onClick={() => handleAddToCart(item)}
    >
      {item.photo ? (
        <img
          style={{
            maxHeight: 250,
            objectFit: "cover",
          }}
          src={item.photo}
          alt={item.title}
        />
      ) : (
        <img
          style={{
            maxHeight: 250,
            objectFit: "cover",
            borderRadius: "20px 20px 0 0",
          }}
          src={"/static/img/product-placeholder-400-400.png"}
          alt={item.title}
        />
      )}
      <div
        style={{
          fontFamily: "Arial, Helvetica, sans-serif",
          padding: "1em",
          height: "100%",
        }}
      >
        <Typography style={{ fontSize: 20 }}>{item.name}</Typography>
        <Typography>SKU: {item.sku}</Typography>
        <Typography>
          Retail Price: {currencyFormatter.format(item.price)}
        </Typography>

        {/* <p style={{ maxHeight: 200, overflow: "hidden" }}>{item.description}</p> */}
      </div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={item.name + "-description"}
          id={item.name + "-description"}
        >
          <Typography component="span">Description</Typography>
        </AccordionSummary>
        <AccordionDetails>{item.description}</AccordionDetails>
      </Accordion>
      <div style={{ margin: "auto" }}>
        <StockStatus qty_value={item.quantity_in_stock} />
      </div>
      <Button
        style={{
          borderRadius: "5px 5px 5px 5px",
          margin: "0px 10px 10px 10px",
        }}
        onClick={() => {}}
        variant="contained"
        color="primary"
      >
        Add to cart
      </Button>
    </div>
  );
};

export default Item;
