import SearchIcon from "@mui/icons-material/Search";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";

import {
  Alert,
  Button,
  CardContent,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Typography as MuiTypography,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

import { ContactSelector } from "../../../components/ContactSelector";

import Cart from "../../../components/cart/Cart";
import CartSummary from "../../../components/cart/CartSummary";

import { makeStyles } from "@mui/styles";

import { spacing } from "@mui/system";

import { Edit, Save } from "@mui/icons-material";
import { Form, Formik } from "formik";
import MuiPhoneNumber from "material-ui-phone-number";
import { useNavigate } from "react-router-dom";

const axios = require("axios").default;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Typography = styled(MuiTypography)(spacing);

const Centered = styled.div`
  text-align: center;
`;

const ProductImg = styled.img`
  display: inline-block;
  height: auto;
  width: 128px;
`;

const StatsIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;

  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const ProductsChip = styled(Chip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

function QuoteCreator(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoading, setLoading] = React.useState(true);
  const [showProductSelector, setShowProductSelector] = React.useState(false);
  const { id } = useParams();

  // Customer Address book variables
  const [selectedContact, setSelectedContact] = React.useState(
    state?.customer ? state.customer : null
  );
  const [contactAction, setContactAction] = React.useState(0);
  // 0 = Save contact information for this quote only
  // 1 = Update existing contact information
  // 2 = Save contact information as a new contact.

  const handleContactAction = (event, newSelection) => {
    setContactAction(newSelection);
  };

  const [validUntil, setValidUntil] = React.useState(dayjs().add(30, "day"));

  const [edit, setEdit] = React.useState(true);
  const [quotes, setQuotes] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setShowProductSelector(false);
    setOpen(false);
  };
  const handleOpen = () => {
    setShowProductSelector(true);
    setOpen(true);
  };

  const [initialValues, setInitialValues] = React.useState({
    po_number: "",
    contact_name: state?.customer ? state.customer.name : "",
    contact_phone: state?.customer ? state.customer.phone : "",
    contact_email: state?.customer ? state.customer.email : "",
    company_name: state?.customer ? state.customer.company_name : "",
    billing_contact_name: state?.customer
      ? state.customer.billing_contact_name
      : "",
    billing_street_address: state?.customer
      ? state.customer.billing_street_address
      : "",
    billing_street_address2: state?.customer
      ? state.customer.billing_street_address2
      : "",
    billing_city: state?.customer ? state.customer.billing_city : "",
    billing_state: state?.customer ? state.customer.billing_state : "",
    billing_zip: state?.customer ? state.customer.billing_zip : "",
    billing_phone: state?.customer ? state.customer.billing_phone : "",
    shipping_contact_name: state?.customer
      ? state.customer.shipping_contact_name
      : "",
    shipping_street_address: state?.customer
      ? state.customer.shipping_street_address
      : "",
    shipping_street_address2: state?.customer
      ? state.customer.shipping_street_address2
      : "",
    shipping_city: state?.customer ? state.customer.shipping_city : "",
    shipping_state: state?.customer ? state.customer.shipping_state : "",
    shipping_zip: state?.customer ? state.customer.shipping_zip : "",
    shipping_phone: state?.customer ? state.customer.shipping_phone : "",
    comments: "",
    discount: "0.00",
    shipping_cost: "",
    cart_items: state?.cart_items || [],
  });

  const useStyles = makeStyles(() => ({
    root: {
      "& .Mui-active": {},
      "& .Mui-completed": { color: "green" },
      "& .Mui-disabled .MuiStepIcon-root": { color: "gray" },
      "& .MuiStepIcon-text": { color: "black" },
    },
  }));

  const timerRef = useRef();
  const descriptionElementRef = React.useRef(null);
  // React.useEffect(() => {
  //   if (open) {
  //     const { current: descriptionElement } = descriptionElementRef;
  //     if (descriptionElement !== null) {
  //       descriptionElement.focus();
  //     }
  //   }
  // }, [open]);

  function handleContactSelection(contact) {
    setSelectedContact(contact);
    let newContact = {
      contact_name: contact.name,
      contact_email: contact.email,
      contact_phone: contact.phone,
      company_name: contact.company_name,

      billing_contact_name: contact.billing_contact_name,
      billing_street_address: contact.billing_street_address,
      billing_street_address2: contact.billing_street_address2,
      billing_city: contact.billing_city,
      billing_state: contact.billing_state,
      billing_zip: contact.billing_zip,
      billing_phone: contact.billing_phone,
      shipping_contact_name: contact.shipping_contact_name,
      shipping_street_address: contact.shipping_street_address,
      shipping_street_address2: contact.shipping_street_address2,
      shipping_city: contact.shipping_city,
      shipping_state: contact.shipping_state,
      shipping_zip: contact.shipping_zip,
      shipping_phone: contact.shipping_phone,
    };

    setInitialValues((prev) => {
      return { ...prev, ...newContact };
    });
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    // Handle form submission logic here

    // Save contact info
    if (contactAction === 0) {
      // Do nothing
    } else if (contactAction === 1) {
      // Update existing
      let updatedContact = {
        name: values.contact_name,
        email: values.contact_email,
        phone: values.contact_phone,
        company_name: values.company_name,
        billing_contact_name: values.billing_contact_name,
        billing_street_address: values.billing_street_address,
        billing_street_address2: values.billing_street_address2,
        billing_city: values.billing_city,
        billing_state: values.billing_state,
        billing_zip: values.billing_zip,
        billing_phone: values.billing_phone,
        shipping_contact_name: values.shipping_contact_name,
        shipping_street_address: values.shipping_street_address,
        shipping_street_address2: values.shipping_street_address2,
        shipping_city: values.shipping_city,
        shipping_state: values.shipping_state,
        shipping_zip: values.shipping_zip,
        shipping_phone: values.shipping_phone,
      };

      axios
        .put("/customers/" + selectedContact.id + "/", updatedContact)
        .then((response) => {})
        .catch((error) => {
          console.log("Error updating contact: ", error);
        });
    } else if (contactAction === 2) {
      // Save as new
      let newContact = {
        name: values.contact_name,
        email: values.contact_email,
        phone: values.contact_phone,
        company_name: values.company_name,
        billing_contact_name: values.billing_contact_name,
        billing_street_address: values.billing_street_address,
        billing_street_address2: values.billing_street_address2,
        billing_city: values.billing_city,
        billing_state: values.billing_state,
        billing_zip: values.billing_zip,
        billing_phone: values.billing_phone,
        shipping_contact_name: values.shipping_contact_name,
        shipping_street_address: values.shipping_street_address,
        shipping_street_address2: values.shipping_street_address2,
        shipping_city: values.shipping_city,
        shipping_state: values.shipping_state,
        shipping_zip: values.shipping_zip,
        shipping_phone: values.shipping_phone,
      };

      axios
        .post("/customers/", newContact)
        .then((response) => {})
        .catch((error) => {
          console.log("Error updating contact: ", error);
        });
    }

    values.new_cart_items = values.cart_items.map((item) => {
      return {
        product: item.product.id,
        quantity: item.quantity,
        discount: item.discount || 0,
      };
    });

    values.valid_until = validUntil.format("YYYY-MM-DD");

    await axios
      .post("/quotes/", values)
      .then((resp) => {
        if (resp.data.id) {
          navigate("/quotes/" + resp.data.id, { data: resp.data });
        }
      })
      .catch((error) => {
        console.log(error.message);
        alert("Error submitting! ", error);
      });
    setSubmitting(false);
  };

  const ContactActionText = () => {
    switch (contactAction) {
      case 0:
        // code block
        return (
          <Typography variant="body1">
            Save contact information for this quote only.
          </Typography>
        );
      case 1:
        // code block
        return (
          <Typography variant="body1">
            Update existing contact information.
          </Typography>
        );
      case 2:
        // code block
        return (
          <Typography variant="body1">
            Save contact information as a new contact.
          </Typography>
        );
        break;
      default:
        return (
          <Typography variant="body1">
            Save contact information for this quote only.
          </Typography>
        );
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Order Status" />
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          company_name: Yup.string()
            .max(250)
            .required("Contact name is required"),
          discount: Yup.number()
            .min(0.0, "Discount must be 0-100")
            .max(100.0, "Discount must be less than 100"),
          contact_name: Yup.string()
            .max(250)
            .required("Contact name is required"),
          cart_items: Yup.array()
            .min(1, "At least one item is required")
            .required("At least one item is required"),
          contact_email: Yup.string().email("Must be a valid email").max(250),
          shipping_cost: Yup.number().required("Shipping Cost is Required"),
        })}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(form) => (
          <Form>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Typography variant="h1" gutterBottom display="inline">
                  Quote
                </Typography>

                <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                  <Link component={NavLink} to="/">
                    Dashboard
                  </Link>
                  <Link component={NavLink} to="/quotes">
                    Quotes
                  </Link>
                  <Typography>Create</Typography>
                </Breadcrumbs>
              </div>
              <div style={{ display: "flex", flexDirection: "column-reverse" }}>
                {edit ? (
                  <IconButton
                    aria-label="details"
                    size="large"
                    onClick={() => {
                      setEdit(!edit);
                    }}
                  >
                    <Save />
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="details"
                    size="large"
                    onClick={() => {
                      setEdit(!edit);
                    }}
                  >
                    <Edit />
                  </IconButton>
                )}
              </div>
            </div>
            <Divider my={6} />
            {
              <div>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6">Customer</Typography>
                        <Grid container spacing={6}>
                          <Grid item xs={12}>
                            <Grid
                              item
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                backgroundColor: "white",
                                padding: 0,
                                marginTop: 10,
                                // marginLeft: 40,
                                alignItems: "end",
                                // borderRadius: 4,
                                paddingBottom: 5,
                              }}
                              justifyContent="space-between"
                              container
                              // spacing={10}
                            >
                              <SearchIcon
                                style={{ marginBottom: 10, marginLeft: 20 }}
                              />
                              <div
                                style={{
                                  flex: 1,
                                  paddingRight: 20,
                                  paddingLeft: 10,
                                }}
                              >
                                <ContactSelector
                                  selectedContact={selectedContact}
                                  handleContactSelection={
                                    handleContactSelection
                                  }
                                />
                              </div>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <TextField
                              name="company_name"
                              label="Company Name"
                              fullWidth
                              variant="outlined"
                              my={2}
                              value={form.values.company_name}
                              helperText={
                                form.touched.company_name &&
                                form.errors.company_name
                              }
                              error={Boolean(
                                form.errors.company_name &&
                                  form.touched.company_name
                              )}
                              onChange={form.handleChange}
                              onBlur={form.handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              name="contact_name"
                              label="Contact Name"
                              fullWidth
                              variant="outlined"
                              my={2}
                              value={form.values.contact_name}
                              helperText={
                                form.touched.contact_name &&
                                form.errors.contact_name
                              }
                              // error={Boolean(
                              //   form.errors.contact_name &&
                              //     form.touched.contact_name
                              // )}
                              onChange={form.handleChange}
                              onBlur={form.handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MuiPhoneNumber
                              name="contact_phone"
                              label="Phone Number"
                              value={form.values.contact_phone}
                              data-cy="number"
                              defaultCountry={"us"}
                              onChange={(e) =>
                                form.setFieldValue("contact_phone", e)
                              }
                              onBlur={form.handleBlur}
                              // helperText={touched.phone && errors.phone}
                              // error={Boolean(touched.phone && errors.phone)}
                              fullWidth
                              type="tel"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <TextField
                              name="contact_email"
                              label="Email"
                              fullWidth
                              variant="outlined"
                              my={2}
                              value={form.values.contact_email}
                              helperText={
                                form.touched.contact_email
                                  ? form.errors.contact_email
                                  : ""
                              }
                              error={
                                form.touched.contact_email &&
                                Boolean(form.errors.contact_email)
                              }
                              onChange={form.handleChange}
                              onBlur={form.handleBlur}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Card>
                      <CardContent>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <Typography variant="h6">Bill To</Typography>
                          <Button
                            inputProps={{ "aria-label": "controlled" }}
                            onClick={() => {
                              const newFormValues = form.values;
                              newFormValues.shipping_contact_name =
                                newFormValues.billing_contact_name;
                              newFormValues.shipping_street_address =
                                newFormValues.billing_street_address;
                              newFormValues.shipping_street_address2 =
                                newFormValues.billing_street_address2;
                              newFormValues.shipping_city =
                                newFormValues.billing_city;
                              newFormValues.shipping_state =
                                newFormValues.billing_state;
                              newFormValues.shipping_zip =
                                newFormValues.billing_zip;
                              newFormValues.shipping_phone =
                                newFormValues.billing_phone;
                              form.setValues({ ...newFormValues });
                            }}
                          >
                            Copy to Shipping
                          </Button>
                        </div>
                        <div style={{ margin: 20 }}>
                          <TextField
                            name="billing_contact_name"
                            label="Billing Contact Name"
                            fullWidth
                            variant="outlined"
                            my={2}
                            value={form.values.billing_contact_name}
                            // error={Boolean(form.errors.firstName && form.touched.firstName)}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                          />
                        </div>
                        <div style={{ margin: 20 }}>
                          <MuiPhoneNumber
                            name="billing_phone"
                            label="Billing Phone"
                            value={form.values.billing_phone}
                            data-cy="number"
                            defaultCountry={"us"}
                            onChange={(e) =>
                              form.setFieldValue("billing_phone", e)
                            }
                            onBlur={form.handleBlur}
                            // helperText={touched.contact_phone && errors.contact_phone}
                            // error={Boolean(touched.contact_phone && errors.contact_phone)}
                            fullWidth
                            type="tel"
                            variant="outlined"
                          />
                        </div>
                        <div style={{ margin: 20 }}>
                          <TextField
                            name="billing_street_address"
                            label="Billing Street Address"
                            fullWidth
                            variant="outlined"
                            my={2}
                            value={form.values.billing_street_address}
                            // error={Boolean(form.errors.firstName && form.touched.firstName)}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                          />
                        </div>
                        <div style={{ margin: 20 }}>
                          <TextField
                            name="billing_street_address2"
                            label="Billing Street Address2"
                            fullWidth
                            variant="outlined"
                            my={2}
                            value={form.values.billing_street_address2}
                            // error={Boolean(form.errors.firstName && form.touched.firstName)}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                          />
                        </div>
                        <div style={{ margin: 20 }}>
                          <TextField
                            name="billing_city"
                            label="Billing City"
                            fullWidth
                            variant="outlined"
                            my={2}
                            value={form.values.billing_city}
                            // error={Boolean(form.errors.firstName && form.touched.firstName)}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                          />
                        </div>
                        <div style={{ margin: 20 }}>
                          <TextField
                            name="billing_state"
                            label="Billing State"
                            fullWidth
                            variant="outlined"
                            my={2}
                            value={form.values.billing_state}
                            inputProps={{ maxLength: 2 }}
                            // error={Boolean(form.errors.firstName && form.touched.firstName)}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                          />
                        </div>
                        <div style={{ margin: 20 }}>
                          <TextField
                            name="billing_zip"
                            label="Billing Zip"
                            fullWidth
                            variant="outlined"
                            my={2}
                            value={form.values.billing_zip}
                            // error={Boolean(form.errors.firstName && form.touched.firstName)}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                          />
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6">Ship To</Typography>
                        <div style={{ margin: 20 }}>
                          <TextField
                            name="shipping_contact_name"
                            label="Shipping Contact Name"
                            fullWidth
                            variant="outlined"
                            my={2}
                            value={form.values.shipping_contact_name}
                            // error={Boolean(form.errors.firstName && form.touched.firstName)}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                          />
                        </div>
                        <div style={{ margin: 20 }}>
                          <MuiPhoneNumber
                            name="shipping_phone"
                            label="Shipping Phone Number"
                            value={form.values.shipping_phone}
                            data-cy="number"
                            defaultCountry={"us"}
                            onChange={(e) =>
                              form.setFieldValue("shipping_phone", e)
                            }
                            onBlur={form.handleBlur}
                            // helperText={touched.phone && errors.phone}
                            // error={Boolean(touched.phone && errors.phone)}
                            fullWidth
                            type="tel"
                            variant="outlined"
                          />
                        </div>
                        <div style={{ margin: 20 }}>
                          <TextField
                            name="shipping_street_address"
                            label="Shipping Street Address"
                            fullWidth
                            variant="outlined"
                            my={2}
                            value={form.values.shipping_street_address}
                            // error={Boolean(form.errors.firstName && form.touched.firstName)}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                          />
                        </div>
                        <div style={{ margin: 20 }}>
                          <TextField
                            name="shipping_street_address2"
                            label="Shipping Street Address2"
                            fullWidth
                            variant="outlined"
                            my={2}
                            value={form.values.shipping_street_address2}
                            // error={Boolean(form.errors.firstName && form.touched.firstName)}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                          />
                        </div>
                        <div style={{ margin: 20 }}>
                          <TextField
                            name="shipping_city"
                            label="Shipping City"
                            fullWidth
                            variant="outlined"
                            my={2}
                            value={form.values.shipping_city}
                            // error={Boolean(form.errors.firstName && form.touched.firstName)}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                          />
                        </div>
                        <div style={{ margin: 20 }}>
                          <TextField
                            name="shipping_state"
                            label="Shipping State"
                            fullWidth
                            variant="outlined"
                            my={2}
                            value={form.values.shipping_state}
                            inputProps={{ maxLength: 2 }}
                            // error={Boolean(form.errors.firstName && form.touched.firstName)}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                          />
                        </div>
                        <div style={{ margin: 20 }}>
                          <TextField
                            name="shipping_zip"
                            label="Shipping Zip"
                            fullWidth
                            variant="outlined"
                            my={2}
                            value={form.values.shipping_zip}
                            // error={Boolean(form.errors.firstName && form.touched.firstName)}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                          />
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6">Contact Actions</Typography>
                        <br />
                        <ToggleButtonGroup
                          color="primary"
                          value={contactAction}
                          exclusive
                          onChange={handleContactAction}
                          aria-label="Platform"
                        >
                          <ToggleButton value={0}>Save</ToggleButton>
                          {selectedContact && (
                            <ToggleButton value={1}>
                              Update Existing
                            </ToggleButton>
                          )}
                          <ToggleButton value={2}>Save as New</ToggleButton>
                        </ToggleButtonGroup>
                        <br />
                        <br />
                        <ContactActionText />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6">Valid Until</Typography>
                        <div style={{ margin: 20 }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              defaultValue={dayjs().add(30, "day")}
                              onChange={(date) => {
                                setValidUntil(date);
                              }}
                            />
                          </LocalizationProvider>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6">Comments</Typography>
                        <div style={{ margin: 20 }}>
                          <TextField
                            multiline
                            rows={4}
                            name="comments"
                            label="Add a comment to this quote"
                            fullWidth
                            variant="outlined"
                            my={2}
                            value={form.values.comments}
                            // error={Boolean(form.errors.firstName && form.touched.firstName)}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            inputProps={{ maxLength: 250 }}
                          />
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        {Boolean(
                          form.errors.cart_items && form.touched.cart_items
                        ) ? (
                          <Alert severity="error">
                            {form.touched.cart_items && form.errors.cart_items}
                          </Alert>
                        ) : (
                          <></>
                        )}
                        <Cart
                          cartItems={form.values.cart_items}
                          setCartItems={(update) => {
                            const prev = form.values;
                            if (typeof update === "function") {
                              prev.cart_items = update(prev.cart_items);

                              form.setFieldValue("cart_items", prev.cart_items);
                            } else {
                              form.setFieldValue("cart_items", update);
                            }
                          }}
                          discount={form.values.discount}
                          edit={true}
                        />
                      </CardContent>
                    </Card>
                    <Card my={1}>
                      <CardContent>
                        <Grid container rowSpacing={3}>
                          <Grid
                            item
                            style={{
                              display: "flex",
                              justifyContent: "right",
                              alignItems: "center",
                              textAlign: "right",
                            }}
                            xs={12}
                          >
                            <TextField
                              id="discount"
                              name="discount"
                              label="% Discount"
                              fullWidth
                              variant="outlined"
                              my={2}
                              value={form.values.discount}
                              // error={Boolean(form.errors.firstName && form.touched.firstName)}
                              error={Boolean(
                                form.errors.discount && form.touched.discount
                              )}
                              helperText={
                                form.touched.discount && form.errors.discount
                              }
                              onChange={form.handleChange}
                              onBlur={form.handleBlur}
                              style={{ maxWidth: 200, alignSelf: "flex-end" }}
                            />
                          </Grid>
                          <Grid
                            item
                            style={{
                              display: "flex",
                              justifyContent: "right",
                              alignItems: "center",
                              textAlign: "right",
                            }}
                            xs={12}
                          >
                            <TextField
                              id="shipping_cost"
                              name="shipping_cost"
                              label="Shipping Cost"
                              fullWidth
                              variant="outlined"
                              my={2}
                              value={form.values.shipping_cost}
                              // error={Boolean(form.errors.firstName && form.touched.firstName)}
                              error={Boolean(
                                form.errors.shipping_cost &&
                                  form.touched.shipping_cost
                              )}
                              helperText={
                                form.touched.shipping_cost &&
                                form.errors.shipping_cost
                              }
                              onChange={form.handleChange}
                              onBlur={form.handleBlur}
                              style={{ maxWidth: 200, alignSelf: "flex-end" }}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                    <Card my={1}>
                      <CardContent>
                        <CartSummary
                          cartItems={form.values.cart_items}
                          discount={form.values.discount}
                          shipping_cost={form.values.shipping_cost}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <br />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  // disabled={isSubmitting}
                >
                  Save Quote
                </Button>
              </div>
            }
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default QuoteCreator;
