import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useParams } from "react-router-dom";
import styled from "styled-components";

import {
  Alert,
  Autocomplete,
  Button,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Typography as MuiTypography,
  Select,
  TextField,
} from "@mui/material";

import { spacing } from "@mui/system";

import { useNavigate } from "react-router-dom";

import { Edit, Lock, OpenInNew, RemoveRedEye, Save } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { FieldArray, Form, Formik } from "formik";
import MuiPhoneNumber from "material-ui-phone-number";
import * as Yup from "yup";
import { ContactSelector } from "../../../components/ContactSelector.js";
import Cart from "../../../components/cart/Cart.js";
import CartSummary from "../../../components/cart/CartSummary.js";
import useAuth from "../../../hooks/useAuth.js";
import { getDate } from "../../../utils/getDate.js";
import Progress from "./Progress.js";

import { apiurl } from "../../../config.js";

const axios = require("axios").default;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Typography = styled(MuiTypography)(spacing);

const Centered = styled.div`
  text-align: center;
`;

const ProductImg = styled.img`
  display: inline-block;
  height: auto;
  width: 128px;
`;

const StatsIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;

  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const ProductsChip = styled(Chip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const processProgress = (order) => {
  if (order.status === "Cancel") {
    return -1;
  }
  if (order.status === "Hold") {
    return 0;
  }
  if (order.status === "Modified") {
    return 0;
  }
  if (order.status === "Shipped") {
    return 3;
  }
  if (order.status === "Picked") {
    return 2;
  }
  if (order.status === "Accepted") {
    return 1;
  }
  if (order.status === "Ordered") {
    return 0;
  }
  return 0;
};

const SerialEntry = (props) => {
  const items = props.cart_items.filter(
    (item) => item.product?.has_serialnumber
  );

  let itemArr = [];
  items.map((item) => {
    const sn = [];
    for (let i = 0; i < item.quantity; i++) {
      sn.push({
        id: String(i),
        serial_number: "",
      });
    }
    itemArr.push({
      id: item.id,
      serial_numbers: sn,
    });
  });
  const initialValues = {
    cart_items: itemArr,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        values.cart_items = values.cart_items.map((item) => {
          return {
            ...item,
            serial_numbers: item.serial_numbers.map((sn) => {
              return sn.serial_number;
            }),
          };
        });

        try {
          axios
            .post("/orders/" + props.id + "/pick/", values)
            .then((response) => {
              props.setOrder(response.data);
            });
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form>
          {items.map((item, index) => {
            return (
              <Grid
                key={"item" + index}
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                mb={2}
              >
                <Grid item m={6}>
                  <Typography fontWeight="bold">
                    {item.product.name} (x){item.quantity}
                  </Typography>
                </Grid>
                <FieldArray name="items">
                  {() => {
                    return (
                      <div>
                        {values.cart_items
                          .find((ct) => ct.id == item.id)
                          .serial_numbers.map((e, i) => {
                            const id = values.cart_items.findIndex(
                              (ct) => ct.id == item.id
                            );
                            const serial_number = `cart_items[${id}].serial_numbers.[${i}].serial_number`;
                            return (
                              <div key={e.id}>
                                <Grid item my={2}>
                                  <TextField
                                    key={"Serial Number #" + String(1 + i)}
                                    margin="normal"
                                    variant="outlined"
                                    label={"Serial Number #" + String(1 + i)}
                                    name={serial_number}
                                    value={e.i}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </Grid>
                              </div>
                            );
                          })}
                      </div>
                    );
                  }}
                </FieldArray>
              </Grid>
            );
          })}
          <Button
            style={{ margin: 20 }}
            size="small"
            disableElevation
            variant="contained"
            type="submit"
          >
            Submit Picked
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const ActionButtons = (props) => {
  const [input, setInput] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [showHold, setShowHold] = useState(false);

  if (props.status === "Modified")
    return (
      <div>
        <Button
          style={{ margin: 20 }}
          size="small"
          disableElevation
          variant="contained"
          onClick={() => {
            axios.post("/orders/" + props.id + "/modify/").then((response) => {
              props.setOrder(response);
            });
          }}
        >
          Accept Changes
        </Button>
      </div>
    );
  if (props.status === "Hold")
    return (
      <div>
        {showInput ? (
          <div>
            <TextField
              id="outlined-multiline-static"
              label="Hold Reason"
              multiline
              rows={4}
              defaultValue="1 week delay"
              onChange={(e) => setInput(e.target.value)}
              value={input}
            />
            <Button
              style={{ margin: 20 }}
              sx={{
                backgroundColor: "#d32f2f",
                "&:hover": {
                  backgroundColor: "#ac5700",
                },
              }}
              size="small"
              disableElevation
              variant="contained"
              onClick={() => {
                axios
                  .post("/orders/" + props.id + "/hold/", {
                    reason: input,
                  })
                  .then((response) => {
                    props.setOrder(response);
                  });
              }}
            >
              Submit Update
            </Button>{" "}
          </div>
        ) : (
          <div>
            <Button
              style={{ margin: 20 }}
              size="small"
              disableElevation
              variant="contained"
              onClick={() => {
                axios
                  .post("/orders/" + props.id + "/accept/")
                  .then((response) => {
                    props.setOrder(response.data);
                  });
              }}
            >
              Accept
            </Button>
            <Button
              style={{ margin: 20 }}
              sx={{
                backgroundColor: "#d32f2f",
                "&:hover": {
                  backgroundColor: "#ac5700",
                },
              }}
              size="small"
              disableElevation
              variant="contained"
              onClick={() => {
                setShowInput(true);
              }}
            >
              Update Hold
            </Button>
          </div>
        )}
      </div>
    );
  if (props.status === "Ordered")
    return (
      <div>
        {showInput && (
          <TextField
            id="outlined-multiline-static"
            label="Hold Reason"
            multiline
            rows={4}
            style={{ width: "500px" }}
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
        )}
        <div>
          <Button
            style={{ margin: 20 }}
            size="small"
            disableElevation
            variant="contained"
            onClick={() => {
              axios
                .post("/orders/" + props.id + "/accept/")
                .then((response) => {
                  props.setOrder(response.data);
                });
            }}
          >
            Accept
          </Button>
          {showInput ? (
            <Button
              style={{ margin: 20 }}
              sx={{
                backgroundColor: "#d32f2f",
                "&:hover": {
                  backgroundColor: "#ac5700",
                },
              }}
              size="small"
              disableElevation
              variant="contained"
              onClick={() => {
                axios
                  .post("/orders/" + props.id + "/hold/", {
                    reason: input,
                  })
                  .then((response) => {
                    props.setOrder(response);
                  });
              }}
            >
              Submit Hold
            </Button>
          ) : (
            <Button
              style={{ margin: 20 }}
              sx={{
                backgroundColor: "#d32f2f",
                "&:hover": {
                  backgroundColor: "#ac5700",
                },
              }}
              size="small"
              disableElevation
              variant="contained"
              onClick={() => {
                setShowInput(true);
              }}
            >
              Hold
            </Button>
          )}
        </div>
      </div>
    );
  if (props.status === "Accepted")
    return (
      <div>
        {showInput &&
          (showHold ? (
            <TextField
              id="outlined-multiline-static"
              label="Hold Reason"
              multiline
              rows={4}
              style={{ width: "500px" }}
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
          ) : (
            <SerialEntry {...props} />
          ))}
        {showInput ? (
          showHold ? (
            <Button
              style={{ margin: 20 }}
              sx={{
                backgroundColor: "#d32f2f",
                "&:hover": {
                  backgroundColor: "#ac5700",
                },
              }}
              size="small"
              disableElevation
              variant="contained"
              onClick={() => {
                axios
                  .post("/orders/" + props.id + "/hold/", {
                    reason: input,
                  })
                  .then((response) => {
                    props.setOrder(response.data);
                  });
              }}
            >
              Submit Hold
            </Button>
          ) : (
            <></>
            // <Button
            //   style={{ margin: 20 }}
            //   size="small"
            //   disableElevation
            //   variant="contained"
            //   onClick={() => {
            //     axios
            //       .post("/orders/" + props.id + "/pick/", {})
            //       .then((response) => {
            //         props.setOrder(response.data);
            //       });
            //   }}
            // >
            //   Submit Picked
            // </Button>
          )
        ) : (
          <div>
            <Button
              style={{ margin: 20 }}
              size="small"
              disableElevation
              variant="contained"
              onClick={() => setShowInput(true)}
            >
              Pick
            </Button>
            <Button
              style={{ margin: 20 }}
              sx={{
                backgroundColor: "#d32f2f",
                "&:hover": {
                  backgroundColor: "#ac5700",
                },
              }}
              size="small"
              disableElevation
              variant="contained"
              onClick={() => {
                setShowInput(true);
                setShowHold(true);
              }}
            >
              Hold
            </Button>
          </div>
        )}
      </div>
    );

  if (props.status === "Picked")
    return (
      <div>
        <Formik
          initialValues={{
            carrier: "Fedex",
            tracking_number: "",
            shipping_cost: "",
          }}
          validationSchema={Yup.object().shape({
            carrier: Yup.string().required("Carrier is required"),
            tracking_number: Yup.string()
              .max(250)
              .required("Tracking Number is required"),
            shipping_cost: Yup.string()
              .matches(/^\d{1,5}(?:\.\d{1,2})?$/, "Invalid cost format")
              .required("Shipping Cost is required"),
            invoice_number: Yup.string().required("Invoice # is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            axios
              .post("/orders/" + props.id + "/ship/", {
                carrier: values.carrier,
                tracking_number: values.tracking_number,
                invoice_number: values.invoice_number,
              })
              .then((response) => {
                props.setOrder(response.data);
              })
              .catch((error) => {
                const message =
                  error.response.data.error || "Something went wrong";

                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form>
              {showInput && (
                <div>
                  <FormControl style={{ width: 100, marginRight: 20 }}>
                    <InputLabel id="carrier">Carrier</InputLabel>
                    <Select
                      labelId="carrier-label"
                      id="carrier"
                      defaultValue={"Fedex"}
                      label="Carrier"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <MenuItem value={"Fedex"}>Fedex</MenuItem>
                      <MenuItem value={"UPS"}>UPS</MenuItem>
                      <MenuItem value={"USPS"}>USPS</MenuItem>
                      <MenuItem value={"DHL"}>DHL</MenuItem>
                      <MenuItem value={"Pickup"}>Pick up</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="tracking_number"
                    label="Tracking Number"
                    style={{ width: "500px", marginBottom: 20 }}
                    defaultValue=""
                    helperText={
                      touched.tracking_number && errors.tracking_number
                    }
                    error={Boolean(
                      errors.tracking_number && touched.tracking_number
                    )}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <TextField
                    id="shipping_cost"
                    label="Shipping Cost"
                    inputProps={{ maxLength: 8 }}
                    style={{ margin: 20, marginTop: 0 }}
                    defaultValue=""
                    helperText={touched.shipping_cost && errors.shipping_cost}
                    error={Boolean(
                      errors.shipping_cost && touched.shipping_cost
                    )}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <TextField
                    id="invoice_number"
                    label="Invoice Number"
                    inputProps={{ maxLength: 8 }}
                    style={{ margin: 20, marginTop: 0 }}
                    defaultValue=""
                    helperText={touched.invoice_number && errors.invoice_number}
                    error={Boolean(
                      errors.invoice_number && touched.invoice_number
                    )}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              )}
              {errors?.submit && (
                <Alert severity="error">{errors.submit}</Alert>
              )}
              {showInput ? (
                <Button
                  style={{ margin: 20 }}
                  size="small"
                  disableElevation
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit Shipping
                </Button>
              ) : (
                <Button
                  style={{ margin: 20 }}
                  size="small"
                  disableElevation
                  variant="contained"
                  onClick={() => {
                    setShowInput(true);
                  }}
                >
                  Ship
                </Button>
              )}
            </Form>
          )}
        </Formik>
      </div>
    );
  return <></>;
};

function Header(props) {
  const progress = processProgress({ status: props.status });
  const { user } = useAuth();

  const isAdmin = user?.is_staff || user?.is_superuser;
  const [timeToEdit, setTimeToEdit] = useState(Date.parse(props.time_to_edit));

  return (
    <Card mb={6}>
      <CardContent>
        <Centered>
          <Typography variant="h1" gutterBottom>
            {props.company_name}
          </Typography>
          <Spacer mb={4} />
          <Typography variant="h4" gutterBottom>
            {props.shipping_city},{props.shipping_state}
          </Typography>
          <Spacer mb={8} />
          {/* <div
            style={{
              display: "flex",
              flex: 1,
              justifySelf: "center",
              alignSelf: "center",
              width: "200px",
              borderWidth: 2,
              height: 2,
              backgroundColor: "pink",
            }}
          /> */}
          <Spacer mb={4} />
          <Typography variant="h4" gutterBottom>
            {props.dealer?.company}
          </Typography>
          {props.edit ? (
            <TextField
              name="po_number"
              label="PO Number"
              fullWidth
              variant="outlined"
              // value={props.formRef.current?.values.po_number} // ?????? THIS WONT WORK IF INCLUDED?
              defaultValue={props.formRef.current?.values.po_number}
              // error={Boolean(form.errors.firstName && form.touched.firstName)}
              onChange={props.formRef.current?.handleChange}
              onBlur={props.formRef.current?.handleBlur}
              sx={{ width: 150 }}
            />
          ) : (
            <Typography
              variant="h4"
              style={{ fontWeight: "bold" }}
              gutterBottom
            >
              PO#: {props.po_number}
            </Typography>
          )}
          {props.status === "Shipped" && (
            <>
              <Typography
                variant="h4"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Invoice #: {props.invoice_number ? props.invoice_number : "N/A"}
              </Typography>
              <Typography
                variant="h4"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Tracking #:{" "}
                {props.tracking_number ? props.tracking_number : "N/A"}
              </Typography>
            </>
          )}

          <Spacer mb={8} />
          <Progress
            step={progress + 1}
            status={props.status}
            holdReason={props.hold_reason}
            stepLabels={[
              getDate(props.date_submitted),
              getDate(props.date_accepted),
              getDate(props.date_picked),
              getDate(props.date_shipped),
            ]}
          ></Progress>
          {isAdmin && (
            <ActionButtons
              status={props.status}
              setOrder={props.setOrder}
              {...props}
            ></ActionButtons>
          )}
          {props.time_to_edit ? "Time to Edit: " + timeToEdit : null}
        </Centered>
      </CardContent>
    </Card>
  );
}

function Dealer(props) {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Dealer
        </Typography>

        <Spacer mb={4} />

        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <Typography>{props?.dealer?.company}</Typography>
            <Typography>{props?.created_by?.first_name}</Typography>
            <Typography>{props?.created_by?.email}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function OrderDetail() {
  const [isLoading, setLoading] = React.useState(true);
  const { id } = useParams();
  const [order, setOrder] = useState({});
  const [quote, setQuote] = useState({});

  const { user } = useAuth();

  // React hack to get submit errors to show
  const [rerender, setRerender] = useState(false);

  const [edit, setEdit] = React.useState(false);

  useEffect(() => {
    axios
      .get("/orders/" + id + "/")
      .then((response) => {
        setOrder(response.data);
      })
      .then(() => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    if (order.created_from_quote) {
      axios.get("/quotes/" + order.created_from_quote).then((response) => {
        setQuote(response.data);
      });
    }
  }, [order]);

  const navigate = useNavigate();

  function handleContactSelection(contact) {
    // const result = mapContactToQuote(contact);
    contact.contact_email = contact.email;
    delete contact.email;
    contact.contact_name = contact.name;
    delete contact.name;
    const newOrder = { ...order, ...contact };

    setOrder(newOrder);
  }

  const formRef = React.useRef();
  const [value, setValue] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");

  const [stakeHolders, setStakeHolders] = React.useState([]);

  useEffect(() => {
    if (order.stakeholders) {
      setStakeHolders(order.stakeholders["emails"]);
    }
  }, [order]);

  let stakeholderOptions = [];
  order.contact_email &&
    stakeholderOptions.push({ label: String(order.contact_email) });
  // order.dealer.email &&
  //   stakeholderOptions.push({ label: String(order.dealer.email) });

  const handleDelete = () => {
    setEdit(false);
    try {
      axios.delete("/orders/" + id + "/").then((response) => {
        navigate("/orders/");
      });
    } catch (error) {
      const message = error.message || "Something went wrong";

      // setStatus({ success: false });
      // setErrors({ submit: message });
      // setSubmitting(false);
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExport = async () => {
    let url = apiurl + "/orders/" + id + "/download_pdf";
    var pdfredirect = window.open(url, "_blank");
    pdfredirect.focus();
  };

  const viewConfirmation = async () => {
    let url = order.order_confirmation;
    var confirmationredirect = window.open(url, "_blank");
    confirmationredirect.focus();
  };

  return (
    <React.Fragment>
      <Helmet title="Order Status" />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="h1" gutterBottom display="inline">
            Order# {order.order_number}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/orders">
              Orders
            </Link>
            <Typography>Order Detail</Typography>
          </Breadcrumbs>
        </div>
        <div style={{ display: "flex", flexDirection: "column-reverse" }}>
          {edit && (
            <IconButton
              aria-label="details"
              size="large"
              onClick={() => {
                if (formRef.current) {
                  formRef.current.handleSubmit();
                  // setEdit(false);
                }
              }}
            >
              <Save />
            </IconButton>
          )}
          {!edit && (order.customer_editable || user?.is_staff) && (
            <IconButton
              aria-label="details"
              size="large"
              onClick={() => {
                setEdit(true);
              }}
            >
              <Edit />
            </IconButton>
          )}

          {!order.customer_editable && !user?.is_staff && (
            <IconButton aria-label="details" size="large" disabled>
              <Lock />
            </IconButton>
          )}
        </div>
      </div>
      <Divider my={6} />

      {isLoading && <CircularProgress />}

      {!isLoading && (
        <div>
          <Header
            {...order}
            formRef={formRef}
            edit={edit}
            setOrder={setOrder}
          ></Header>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={12} xl={12}>
              <Formik
                innerRef={formRef}
                initialValues={order}
                validationSchema={Yup.object().shape({
                  contact_name: Yup.string()
                    .max(250)
                    .required("Contact name is required"),
                  company_name: Yup.string().required("Email is required"),
                  contact_phone: Yup.string()
                    .max(25)
                    .required("Phone number is required"),
                  contact_email: Yup.string()
                    // .nullable()
                    .email("Must be a valid email")
                    .required("Email is Required"),
                  billing_contact_name: Yup.string().required(
                    "Billing contact name is required"
                  ),
                  billing_phone: Yup.string()
                    .max(25)
                    .required("Billing phone is required"),
                  billing_street_address: Yup.string().required(
                    "Billing street adress is required"
                  ),
                  billing_city: Yup.string().required(
                    "Billing city is required"
                  ),
                  billing_state: Yup.string()
                    .max(2)
                    .required("Billing state is required"),
                  billing_zip: Yup.string()
                    .max(7)
                    .required("Billing zip is required"),

                  shipping_contact_name: Yup.string().required(
                    "Shipping contact name is required"
                  ),
                  shipping_phone: Yup.string()
                    .max(25)
                    .required("Shipping phone is required"),
                  shipping_street_address: Yup.string().required(
                    "Shipping street adress is required"
                  ),
                  shipping_city: Yup.string().required(
                    "Shipping city is required"
                  ),
                  shipping_state: Yup.string()
                    .max(2)
                    .required("Shipping state is required"),
                  shipping_zip: Yup.string()
                    .max(7)
                    .required("Shipping zip is required"),
                  discount: Yup.number()
                    .min(0.0, "Discount must be 0-100")
                    .max(100.0, "Discount must be less than 100"),
                })}
                enableReinitialize
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  values.new_cart_items = values.cart_items.map((item) => {
                    return {
                      product: item.product.id ? item.product.id : item.product,
                      quantity: item.quantity,
                      discount: item.discount || 0,
                    };
                  });

                  let stakeholdersJson = {
                    emails: stakeHolders,
                  };

                  values.stakeholders = stakeholdersJson;

                  axios
                    .put("/orders/" + id + "/", { ...values })
                    .then((response) => {
                      setEdit(false);

                      if (response.data) {
                        if (values.order_confirmation?.name) {
                          const formData = new FormData();
                          formData.append(
                            "order_confirmation",
                            values.order_confirmation
                          );
                          axios
                            .post(
                              "/orders/" +
                                response.data.id +
                                "/upload_confirmation/",
                              formData
                            )
                            .then((response) => {
                              console.log(
                                "|| Confirmation file uploaded successfully"
                              );
                              setOrder(response.data);
                            });
                        } else {
                          setOrder(response.data);
                        }
                      }
                    })
                    .catch((error) => {
                      console.log("|| error: ", error.response.data);
                      const message =
                        error.response.data[0] || "Something went wrong";

                      setStatus({ success: false });
                      setErrors({ submit: message });
                      setSubmitting(false);
                      setRerender(!rerender);
                    });
                }}
              >
                {(form) => (
                  <Form>
                    {edit ? (
                      <Grid container spacing={6}>
                        <Grid item xs={12}>
                          <Card mb={6}>
                            <CardContent>
                              <Typography variant="h6">Customer</Typography>
                              <Grid container spacing={6}>
                                <Grid item xs={12}>
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      backgroundColor: "white",
                                      padding: 0,
                                      marginTop: 10,
                                      // marginLeft: 40,
                                      alignItems: "end",
                                      // borderRadius: 4,
                                      paddingBottom: 5,
                                    }}
                                    justifyContent="space-between"
                                    container
                                    // spacing={10}
                                  >
                                    <SearchIcon
                                      style={{
                                        marginBottom: 10,
                                        marginLeft: 20,
                                      }}
                                    />
                                    <div
                                      style={{
                                        flex: 1,
                                        paddingRight: 20,
                                        paddingLeft: 10,
                                      }}
                                    >
                                      <ContactSelector
                                        handleContactSelection={
                                          handleContactSelection
                                        }
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <TextField
                                    name="company_name"
                                    label="Company Name"
                                    fullWidth
                                    variant="outlined"
                                    required={true}
                                    my={2}
                                    value={form.values.company_name}
                                    helperText={
                                      form.touched.company_name &&
                                      form.errors.company_name
                                    }
                                    error={Boolean(
                                      form.errors.company_name &&
                                        form.touched.company_name
                                    )}
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    name="contact_name"
                                    label="Contact Name"
                                    fullWidth
                                    variant="outlined"
                                    required={true}
                                    my={2}
                                    value={form.values.contact_name}
                                    helperText={
                                      form.touched.contact_name &&
                                      form.errors.contact_name
                                    }
                                    error={Boolean(
                                      form.errors.contact_name &&
                                        form.touched.contact_name
                                    )}
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <MuiPhoneNumber
                                    name="contact_phone"
                                    label="Phone Number"
                                    value={form.values.contact_phone}
                                    data-cy="number"
                                    required={true}
                                    defaultCountry={"us"}
                                    onChange={form.handleChange(
                                      "contact_phone"
                                    )}
                                    onBlur={form.handleBlur}
                                    helperText={
                                      form.touched.contact_phone &&
                                      form.errors.contact_phone
                                    }
                                    error={Boolean(
                                      form.errors.contact_phone &&
                                        form.touched.contact_phone
                                    )}
                                    fullWidth
                                    type="tel"
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <TextField
                                    name="contact_email"
                                    label="Email"
                                    required={true}
                                    fullWidth
                                    variant="outlined"
                                    my={2}
                                    value={form.values.contact_email}
                                    // error={Boolean(form.errors.firstName && form.touched.firstName)}
                                    helperText={
                                      form.touched.contact_email
                                        ? form.errors.contact_email
                                        : ""
                                    }
                                    error={
                                      form.touched.contact_email &&
                                      Boolean(form.errors.contact_email)
                                    }
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                  />
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container spacing={6}>
                        <Grid item xs={12}>
                          <Card mb={6}>
                            <CardContent>
                              <Typography variant="h6">Customer</Typography>
                              <Grid container spacing={6}>
                                <Grid item xs={12}>
                                  <Spacer mb={4} />

                                  <Typography>{order?.company_name}</Typography>
                                  <Typography>{order?.contact_name}</Typography>
                                  <Typography>
                                    {order?.contact_phone}
                                  </Typography>
                                  <Typography>
                                    {order?.contact_email}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    )}

                    <Grid container spacing={6}>
                      {edit ? (
                        <Grid item xs={12} sm={12} md={12}>
                          <Grid container spacing={6}>
                            <Grid item xs={12} sm={12} md={6}>
                              <Card>
                                <CardContent>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Typography variant="h6">
                                      Bill To
                                    </Typography>
                                    <Button
                                      onClick={() => {
                                        const newFormValues = form.values;
                                        newFormValues.shipping_contact_name =
                                          newFormValues.billing_contact_name;
                                        newFormValues.shipping_street_address =
                                          newFormValues.billing_street_address;
                                        newFormValues.shipping_street_address2 =
                                          newFormValues.billing_street_address2;
                                        newFormValues.shipping_city =
                                          newFormValues.billing_city;
                                        newFormValues.shipping_state =
                                          newFormValues.billing_state;
                                        newFormValues.shipping_zip =
                                          newFormValues.billing_zip;
                                        newFormValues.shipping_phone =
                                          newFormValues.billing_phone;
                                        form.setValues({ ...newFormValues });
                                      }}
                                    >
                                      Copy to Shipping
                                    </Button>
                                  </div>
                                  <Grid
                                    container
                                    spacing={6}
                                    style={{ marginTop: 5 }}
                                  >
                                    <Grid item xs={12} sm={12}>
                                      <TextField
                                        name="billing_contact_name"
                                        label="Billing Contact Name"
                                        fullWidth
                                        variant="outlined"
                                        required={true}
                                        value={form.values.billing_contact_name}
                                        helperText={
                                          form.touched.billing_contact_name &&
                                          form.errors.billing_contact_name
                                        }
                                        error={Boolean(
                                          form.errors.billing_contact_name &&
                                            form.touched.billing_contact_name
                                        )}
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                      <MuiPhoneNumber
                                        name="billing_phone"
                                        label="Billing Phone"
                                        required={true}
                                        value={form.values.billing_phone}
                                        data-cy="number"
                                        defaultCountry={"us"}
                                        onChange={form.handleChange(
                                          "billing_phone"
                                        )}
                                        onBlur={form.handleBlur}
                                        helperText={
                                          form.touched.billing_phone &&
                                          form.errors.billing_phone
                                        }
                                        error={Boolean(
                                          form.errors.billing_phone &&
                                            form.touched.billing_phone
                                        )}
                                        fullWidth
                                        type="tel"
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                      <TextField
                                        name="billing_street_address"
                                        label="Billing Street Address"
                                        fullWidth
                                        variant="outlined"
                                        required={true}
                                        value={
                                          form.values.billing_street_address
                                        }
                                        helperText={
                                          form.touched.billing_street_address &&
                                          form.errors.billing_street_address
                                        }
                                        error={Boolean(
                                          form.errors.billing_street_address &&
                                            form.touched.billing_street_address
                                        )}
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                      <TextField
                                        name="billing_street_address2"
                                        label="Billing Street Address2"
                                        fullWidth
                                        variant="outlined"
                                        value={
                                          form.values.billing_street_address2
                                        }
                                        // error={Boolean(form.errors.firstName && form.touched.firstName)}
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                      <TextField
                                        name="billing_city"
                                        label="City"
                                        fullWidth
                                        variant="outlined"
                                        required={true}
                                        value={form.values.billing_city}
                                        helperText={
                                          form.touched.billing_city &&
                                          form.errors.billing_city
                                        }
                                        error={Boolean(
                                          form.errors.billing_city &&
                                            form.touched.billing_city
                                        )}
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                      <TextField
                                        name="billing_state"
                                        label="State"
                                        fullWidth
                                        variant="outlined"
                                        required={true}
                                        value={form.values.billing_state}
                                        inputProps={{ maxLength: 2 }}
                                        helperText={
                                          form.touched.billing_state &&
                                          form.errors.billing_state
                                        }
                                        error={Boolean(
                                          form.errors.billing_state &&
                                            form.touched.billing_state
                                        )}
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                      <TextField
                                        name="billing_zip"
                                        label="Zip"
                                        fullWidth
                                        variant="outlined"
                                        required={true}
                                        value={form.values.billing_zip}
                                        helperText={
                                          form.touched.billing_zip &&
                                          form.errors.billing_zip
                                        }
                                        error={Boolean(
                                          form.errors.billing_zip &&
                                            form.touched.billing_zip
                                        )}
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                      />
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                              <Card>
                                <CardContent>
                                  <Typography variant="h6">Ship To</Typography>
                                  <Grid
                                    container
                                    spacing={6}
                                    style={{ marginTop: 5 }}
                                  >
                                    <Grid item xs={12} sm={12}>
                                      <TextField
                                        name="shipping_contact_name"
                                        label="Shipping Contact Name"
                                        fullWidth
                                        variant="outlined"
                                        required={true}
                                        value={
                                          form.values.shipping_contact_name
                                        }
                                        helperText={
                                          form.touched.shipping_contact_name &&
                                          form.errors.shipping_contact_name
                                        }
                                        error={Boolean(
                                          form.errors.shipping_contact_name &&
                                            form.touched.shipping_contact_name
                                        )}
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                      <MuiPhoneNumber
                                        name="shipping_phone"
                                        label="Shipping Phone"
                                        required={true}
                                        value={form.values.shipping_phone}
                                        data-cy="number"
                                        defaultCountry={"us"}
                                        onChange={form.handleChange(
                                          "shipping_phone"
                                        )}
                                        onBlur={form.handleBlur}
                                        helperText={
                                          form.touched.shipping_phone &&
                                          form.errors.shipping_phone
                                        }
                                        error={Boolean(
                                          form.errors.shipping_phone &&
                                            form.touched.shipping_phone
                                        )}
                                        fullWidth
                                        type="tel"
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                      <TextField
                                        name="shipping_street_address"
                                        label="Shipping Street Address"
                                        fullWidth
                                        variant="outlined"
                                        required={true}
                                        value={
                                          form.values.shipping_street_address
                                        }
                                        helperText={
                                          form.touched
                                            .shipping_street_address &&
                                          form.errors.shipping_street_address
                                        }
                                        error={Boolean(
                                          form.errors.shipping_street_address &&
                                            form.touched.shipping_street_address
                                        )}
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                      <TextField
                                        name="shipping_street_address2"
                                        label="Shipping Street Address2"
                                        fullWidth
                                        variant="outlined"
                                        value={
                                          form.values.shipping_street_address2
                                        }
                                        // error={Boolean(form.errors.firstName && form.touched.firstName)}
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                      <TextField
                                        name="shipping_city"
                                        label="City"
                                        fullWidth
                                        variant="outlined"
                                        required={true}
                                        value={form.values.shipping_city}
                                        helperText={
                                          form.touched.shipping_city &&
                                          form.errors.shipping_city
                                        }
                                        error={Boolean(
                                          form.errors.shipping_city &&
                                            form.touched.shipping_city
                                        )}
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                      <TextField
                                        name="shipping_state"
                                        label="State"
                                        fullWidth
                                        variant="outlined"
                                        required={true}
                                        value={form.values.shipping_state}
                                        helperText={
                                          form.touched.shipping_state &&
                                          form.errors.shipping_state
                                        }
                                        error={Boolean(
                                          form.errors.shipping_state &&
                                            form.touched.shipping_state
                                        )}
                                        inputProps={{ maxLength: 2 }}
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                      <TextField
                                        name="shipping_zip"
                                        label="Zip"
                                        fullWidth
                                        variant="outlined"
                                        required={true}
                                        value={form.values.shipping_zip}
                                        helperText={
                                          form.touched.shipping_zip &&
                                          form.errors.shipping_zip
                                        }
                                        error={Boolean(
                                          form.errors.shipping_zip &&
                                            form.touched.shipping_zip
                                        )}
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                      />
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid item xs={12} sm={12} md={12}>
                          <Grid container spacing={6}>
                            <Grid item xs={12} sm={12} md={6}>
                              <Card>
                                <CardContent>
                                  <Typography variant="h6">Bill To</Typography>
                                  <br />
                                  <Grid container spacing={6}>
                                    <Grid item>
                                      <div>
                                        <Typography>
                                          {order.billing_contact_name}
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography>
                                          {order.company_name}
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography>
                                          {order.billing_street_address}
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography>
                                          {order.billing_street_address2}
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography>
                                          {order.billing_city},{" "}
                                          {order.billing_state}{" "}
                                          {order.billing_zip}
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography>
                                          {order.billing_phone}
                                        </Typography>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                              <Card mb={6}>
                                <CardContent>
                                  <Typography variant="h6">Ship To</Typography>
                                  <br />
                                  <Grid container spacing={6}>
                                    <Grid item>
                                      <div>
                                        <Typography>
                                          {order.shipping_contact_name}
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography>
                                          {order.company_name}
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography>
                                          {order.shipping_street_address}
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography>
                                          {order.shipping_street_address2}
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography>
                                          {order.shipping_city},{" "}
                                          {order.shipping_state}{" "}
                                          {order.shipping_zip}
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography>
                                          {order.shipping_phone}
                                        </Typography>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}

                      <Grid item xs={12} sm={12}>
                        <Card mb={6}>
                          <CardContent>
                            <Typography variant="h6" gutterBottom>
                              Comments
                            </Typography>

                            <Spacer mb={4} />
                            {edit ? (
                              <TextField
                                id="comments"
                                label="comments"
                                multiline
                                fullWidth
                                rows={4}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                value={form.values.comments}
                                inputProps={{ maxLength: 250 }}
                              />
                            ) : (
                              <Typography>
                                {order.comments
                                  ? order.comments
                                  : "No Comments"}
                              </Typography>
                            )}
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <Cart
                          cartItems={form.values.cart_items}
                          setCartItems={(update) => {
                            const prev = form.values;
                            if (typeof update === "function") {
                              prev.cart_items = update(prev.cart_items);
                              console.log("|| setting initial values: ", prev);
                              form.setFieldValue("cart_items", prev.cart_items);
                            } else {
                              form.setFieldValue("cart_items", update);
                            }
                          }}
                          discount={form.values.discount}
                          edit={edit}
                        />
                        {edit ? (
                          <Card my={1}>
                            <CardContent>
                              <Grid container rowSpacing={1}>
                                <Grid
                                  item
                                  style={{
                                    display: "flex",
                                    justifyContent: "right",
                                    alignItems: "center",
                                    textAlign: "right",
                                  }}
                                  xs={12}
                                >
                                  <h4 style={{ margin: 0, marginRight: 5 }}>
                                    Additional Discount:{" "}
                                  </h4>
                                  <TextField
                                    id="discount"
                                    name="discount"
                                    label="% Discount"
                                    fullWidth
                                    variant="outlined"
                                    my={2}
                                    type="number"
                                    value={form.values.discount}
                                    error={Boolean(
                                      form.errors.discount &&
                                        form.touched.discount
                                    )}
                                    helperText={
                                      form.touched.discount &&
                                      form.errors.discount
                                    }
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                    style={{
                                      maxWidth: 200,
                                      alignSelf: "flex-end",
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        ) : (
                          <></>
                        )}
                        <Card my={1}>
                          <CardContent>
                            <CartSummary
                              cartItems={form.values.cart_items}
                              discount={form.values.discount}
                              shipping_cost={form.values.shipping_cost}
                            />
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <Dealer
                          {...order}
                          edit={edit}
                          submit={() => {
                            setEdit(!edit);
                          }}
                        />
                        <Grid container spacing={6} marginBottom={6}>
                          <Grid item xs={12} sm={12}>
                            <Card mb={6}>
                              <CardContent>
                                <Typography variant="h6" gutterBottom>
                                  Stakeholders
                                </Typography>

                                <Spacer mb={4} />

                                <Autocomplete
                                  multiple
                                  freeSolo
                                  id="tags-standard"
                                  options={[""]}
                                  value={stakeHolders}
                                  inputValue={inputValue}
                                  disabled={!edit}
                                  onChange={(event, newValue) => {
                                    setStakeHolders(newValue);
                                    form.setFieldValue(
                                      "stakeholders",
                                      newValue
                                    );
                                  }}
                                  onInputChange={(event, newInputValue) => {
                                    const options = newInputValue.split(",");

                                    if (options.length > 1) {
                                      setValue(
                                        value
                                          .concat(options)
                                          .map((x) => x.trim())
                                          .filter((x) => x)
                                      );
                                    } else {
                                      setInputValue(newInputValue);
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <div>
                                      {edit ? (
                                        <TextField
                                          {...params}
                                          label="Stakeholders"
                                          placeholder={
                                            edit ? "Add stakeholer email" : ""
                                          }
                                        />
                                      ) : (
                                        stakeHolders.map((stakeholder) => (
                                          <Typography>{stakeholder}</Typography>
                                        ))
                                      )}
                                    </div>
                                  )}
                                />
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>

                        {order?.order_confirmation && (
                          <Card mb={6}>
                            <CardContent>
                              <Typography variant="h6" gutterBottom>
                                Order Confirmation
                              </Typography>

                              <Spacer mb={4} />
                              {edit ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    marginTop: 20,
                                  }}
                                >
                                  <input
                                    type="file"
                                    onChange={(event) => {
                                      form.setFieldValue(
                                        "order_confirmation",
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                  />
                                </div>
                              ) : (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => viewConfirmation()}
                                >
                                  View Document{" "}
                                  <OpenInNew style={{ marginLeft: 3 }} />
                                </Button>
                              )}
                            </CardContent>
                          </Card>
                        )}

                        {user?.quoting_enabled && (
                          <Card mb={6}>
                            <CardContent>
                              <Typography variant="h6" gutterBottom>
                                Quote
                              </Typography>

                              <Spacer mb={4} />
                              <Centered>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography align="left">
                                    {quote.quote_number}
                                  </Typography>
                                  {/* What are we doing about files instead of quote objects? */}
                                  {quote?.id ? (
                                    <div>
                                      <IconButton
                                        aria-label="details"
                                        size="large"
                                        onClick={() =>
                                          navigate("/quote/" + quote.id)
                                        }
                                      >
                                        <RemoveRedEye />
                                      </IconButton>
                                    </div>
                                  ) : (
                                    <Typography align="left">
                                      Customer not quoted
                                    </Typography>
                                  )}
                                </div>
                                <Typography align="left">
                                  {getDate(quote.date_created)}
                                </Typography>
                                {edit ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      marginTop: 20,
                                    }}
                                  >
                                    <input
                                      type="file"
                                      onChange={(event) => {
                                        form.setFieldValue(
                                          "order_confirmation",
                                          event.currentTarget.files[0]
                                        );
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </Centered>
                            </CardContent>
                          </Card>
                        )}

                        <Card mb={6}>
                          <CardContent>
                            <Typography variant="h6" gutterBottom>
                              Actions
                            </Typography>

                            <Spacer mb={4} />
                            <Centered>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleExport()}
                                >
                                  Export PDF
                                </Button>
                              </div>
                            </Centered>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
          {edit && formRef.current.errors.submit && (
            <Alert severity="warning">
              {formRef.current.errors.submit}{" "}
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/order/create")}
              >
                Create a New Order
              </Button>
            </Alert>
          )}
          <div style={{ display: "flex" }}>
            <Dialog
              open={open}
              onClose={handleClose}
              PaperProps={{
                component: "form",
                onSubmit: async (event) => {
                  event.preventDefault();
                  const formData = new FormData(event.currentTarget);
                  const formJson = Object.fromEntries(formData.entries());
                  const po_number = formJson.po_number;
                  const resp = await axios.post(
                    "/quotes/" + id + "/convert_to_order/",
                    { po_number: po_number ? po_number : "12345" }
                  );

                  if (resp.data.order_created) {
                    navigate("/order/" + resp.data.order_created + "/");
                  }
                  handleClose();
                },
              }}
            >
              <DialogTitle>Delete Order</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this order?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleDelete}>Confirm</Button>
              </DialogActions>
            </Dialog>
            {edit && (
              <div style={{ marginTop: 10 }}>
                <Button
                  variant="contained"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    if (formRef.current) {
                      formRef.current.handleSubmit();
                    }
                  }}
                >
                  Save Edits
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleClickOpen}
                >
                  Delete Order
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default OrderDetail;
