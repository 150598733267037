import { useMediaQuery } from "@mui/material";
import styled, { useTheme } from "styled-components";
import { currencyFormatter } from "../../utils/currencyFormatter";

import { Grid, TextField } from "@mui/material";

import React from "react";

const Centered = styled.div`
  text-align: center;
`;

const CartSummary = ({ cartItems, discount, shipping_cost }) => {
  const descriptionElementRef = React.useRef(null);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const containerStyle = smallScreen
    ? {
        marginTop: 0,
        paddingRight: 10,
        minWidth: 300,
        alignContent: "right",
      }
    : {
        marginTop: 0,
        minWidth: 600,
        alignContent: "right",
      };
  const itemStyle = {
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    textAlign: "right",
  };

  const calculateTotal = () => {
    if (cartItems === undefined || cartItems === null) {
      return 0;
    }
    let total = 0;
    for (let i = 0; i < cartItems?.length; i++) {
      let item = cartItems[i];
      let discount = item?.discount || 0;
      let price = item?.price;
      let quantity = item?.quantity;
      let itemTotal = quantity * (price - price * (discount / 100));
      total += itemTotal;
    }
    total = total - total * (Number(discount) / 100);
    total += Number(shipping_cost);
    return total || 0;
  };

  const calculateCCTotal = () => {
    if (cartItems === undefined || cartItems === null) {
      return 0;
    }
    let total = 0;
    for (let i = 0; i < cartItems?.length; i++) {
      let item = cartItems[i];
      let discount = item?.discount || 0;
      let price = item?.price;
      let quantity = item?.quantity;
      let itemTotal = quantity * (price - price * (discount / 100));
      total += itemTotal;
    }
    total = total - total * (Number(discount) / 100);
    total += Number(shipping_cost);
    total += total * 0.04; // 4% credit card fee
    return total || 0;
  };

  const calculateCCFee = () => {
    if (cartItems === undefined || cartItems === null) {
      return 0;
    }
    let total = 0;
    for (let i = 0; i < cartItems?.length; i++) {
      let item = cartItems[i];
      let discount = item?.discount || 0;
      let price = item?.price;
      let quantity = item?.quantity;
      let itemTotal = quantity * (price - price * (discount / 100));
      total += itemTotal;
    }
    total = total - total * (Number(discount) / 100);
    total += Number(shipping_cost);
    return total * 0.04 || 0; // 4% credit card fee
  };

  const calculateSubtotal = () => {
    if (cartItems === undefined || cartItems === null) {
      return 0;
    }
    let total = 0;
    for (let i = 0; i < cartItems?.length; i++) {
      let item = cartItems[i];
      let price = item?.price;
      let quantity = item?.quantity;
      let itemTotal = quantity * price;
      total += itemTotal;
    }
    return total || 0;
  };

  return (
    <Grid container rowSpacing={1} style={containerStyle}>
      <Grid item style={itemStyle} xs={12}>
        <h4 style={{ margin: 0, marginRight: 5 }}>Subtotal: </h4>
        <TextField
          id="subtotal"
          name="subtotal"
          fullWidth
          variant="outlined"
          my={2}
          value={currencyFormatter.format(calculateSubtotal())}
          style={{ maxWidth: 200, alignSelf: "flex-end" }}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>

      {discount && discount > 0 ? (
        <Grid item style={itemStyle} xs={12}>
          <h4 style={{ margin: 0, marginRight: 5 }}>Discount: </h4>
          <TextField
            id="discount"
            name="discount"
            fullWidth
            variant="outlined"
            my={2}
            value={discount + "%"}
            style={{ maxWidth: 200, alignSelf: "flex-end" }}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      ) : (
        <></>
      )}

      {shipping_cost && shipping_cost > 0 ? (
        <Grid item style={itemStyle} xs={12}>
          <h4 style={{ margin: 0, marginRight: 5 }}>Shipping: </h4>
          <TextField
            id="margin-total"
            name="margin-total"
            fullWidth
            variant="outlined"
            my={2}
            value={currencyFormatter.format(shipping_cost)}
            style={{ maxWidth: 200, alignSelf: "flex-end" }}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      ) : (
        <></>
      )}

      <Grid item style={itemStyle} xs={12}>
        <h4 style={{ margin: 0, marginRight: 5 }}>Total: </h4>
        <TextField
          id="total"
          name="total"
          fullWidth
          variant="outlined"
          my={2}
          value={currencyFormatter.format(calculateTotal())}
          style={{ maxWidth: 200, alignSelf: "flex-end" }}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>

      <Grid item style={itemStyle} xs={12}>
        <h4 style={{ margin: 0, marginRight: 5 }}>CC %: </h4>
        <TextField
          id="cc-percent"
          name="cc-percent"
          fullWidth
          variant="outlined"
          my={2}
          value={"4.00%"}
          style={{ maxWidth: 200, alignSelf: "flex-end" }}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>

      <Grid item style={itemStyle} xs={12}>
        <h4 style={{ margin: 0, marginRight: 5 }}>CC Fee: </h4>
        <TextField
          id="cc-fee"
          name="cc-fee"
          fullWidth
          variant="outlined"
          my={2}
          value={currencyFormatter.format(calculateCCFee())}
          style={{ maxWidth: 200, alignSelf: "flex-end" }}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>

      <Grid item style={itemStyle} xs={12}>
        <h4 style={{ margin: 0, marginRight: 5 }}>CC Total: </h4>
        <TextField
          id="cc-total"
          name="cc-total"
          fullWidth
          variant="outlined"
          my={2}
          value={currencyFormatter.format(calculateCCTotal())}
          style={{ maxWidth: 200, alignSelf: "flex-end" }}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CartSummary;
